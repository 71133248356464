import React from 'react';
import style from './Button.scss.json';
import './Button.scss';
import {useStyles} from 'lincd/lib/utils/Hooks';
import {ButtonProps as BaseButtonProps} from '@mui/base/Button';
import {useButton} from '@mui/base/useButton';

interface ButtonProps extends BaseButtonProps
{
  variant?: 'contained' | 'outlined' | 'text';
  color?: 'primary' | string; // adjust the type of color if needed.
  image?: string;
  fullWidth?: boolean;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
}

export const Button = React.forwardRef(function CustomButton(
  props: ButtonProps,
  ref: React.ForwardedRef<HTMLButtonElement>,
) {
  props = useStyles(props,style.buttonRoot);

  const {
    children,
    disabled,
    variant = 'outlined',
    color = 'primary',
    fullWidth = false,
    startIcon,
    endIcon,
  } = props;
  const {active,focusVisible,getRootProps} = useButton({
    ...props,
    rootRef: ref,
  });

  const colorClasses = `
    ${color === 'primary' ? style['primary'] : ''}
  `;

  const additionalClasses = `
    ${disabled ? style['disabled'] : ''}
    ${active ? style['active'] : ''}
    ${focusVisible ? style['focusVisible'] : ''}
    ${fullWidth ? style['fullwidth'] : ''}
  `;

  return (
    <button
      {...getRootProps()}
      className={`${props.className} ${style[variant]} ${colorClasses} ${additionalClasses}`}
    >
      {startIcon && startIcon}
      {children}
      {endIcon && endIcon}
    </button>
  );
});
