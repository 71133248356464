import {linkedShape} from '../package';
import {emergentLives} from '../ontologies/emergent-lives';
import {ClientAccount} from './ClientAccount';
import {literalProperty} from 'lincd/lib/utils/ShapeDecorators';
import {Literal, NamedNode} from 'lincd/lib/models';
import {Boolean} from 'lincd-xsd/lib/shapes/Boolean';

@linkedShape
export class ProviderAccount extends ClientAccount {
  static targetClass: NamedNode = emergentLives.ProviderAccount;

  @literalProperty({
    path: emergentLives.legalName,
    maxCount: 1,
  })
  get legalName(): string {
    return this.getValue(emergentLives.legalName);
  }

  set legalName(val: string) {
    this.overwrite(emergentLives.legalName, new Literal(val));
  }

  @literalProperty({
    path: emergentLives.telephone,
    maxCount: 1,
  })
  get telephone(): string {
    return this.getValue(emergentLives.telephone);
  }

  set telephone(val: string) {
    this.overwrite(emergentLives.telephone, new Literal(val));
  }

  @literalProperty({
    path: emergentLives.code,
    maxCount: 1,
  })
  get code(): string {
    return this.getValue(emergentLives.code);
  }

  set code(val: string) {
    this.overwrite(emergentLives.code, val ? new Literal(val) : null);
  }

  @literalProperty({
    path: emergentLives.eventbriteEmail,
    maxCount: 1,
  })
  get eventbriteEmail(): string {
    return this.getValue(emergentLives.eventbriteEmail);
  }

  set eventbriteEmail(val: string) {
    this.overwrite(emergentLives.eventbriteEmail, new Literal(val));
  }

  @literalProperty({
    path: emergentLives.businessFirstName,
    maxCount: 1,
  })
  get businessFirstName(): string {
    return this.getValue(emergentLives.businessFirstName);
  }

  set businessFirstName(val: string) {
    this.overwrite(emergentLives.businessFirstName, new Literal(val));
  }

  @literalProperty({
    path: emergentLives.businessLastName,
    maxCount: 1,
  })
  get businessLastName(): string {
    return this.getValue(emergentLives.businessLastName);
  }

  set businessLastName(val: string) {
    this.overwrite(emergentLives.businessLastName, new Literal(val));
  }

  @literalProperty({
    path: emergentLives.isVerified,
    dataType: Boolean.targetClass,
  })
  get isVerified(): boolean {
    return Boolean.isTrue(this.getOne(emergentLives.isVerified) as Literal);
  }
  set isVerified(val: boolean) {
    this.overwrite(
      emergentLives.isVerified,
      val ? Boolean.true() : Boolean.false(),
    );
  }
}
