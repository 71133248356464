import {NamedNode} from 'lincd/lib/models';
import {JSONLD} from 'lincd-jsonld/lib/utils/JSONLD';
import {createNameSpace} from 'lincd/lib/utils/NameSpace';
import {linkedOntology} from '../package';
//import all the exports of this file as one variable called _this (we need this at the end)
import * as _this from './lincd-authenticator';

/**
 * Load the data of this ontology into memory, thus adding the properties of the entities of this ontology to the local graph.
 */
export var loadData = () => {
  return import('../data/lincd-authenticator.json').then((data) =>
    JSONLD.parse(data),
  );
};

/**
 * The namespace of this ontology, which can be used to create NamedNodes with URI's not listed in this file
 */
export var ns = createNameSpace('http://lincd.org/ont/lincd-authenticator/');

/**
 * The NamedNode of the ontology itself
 */
export var _self: NamedNode = ns('');

//A list of all the entities (Classes & Properties) of this ontology, each exported as a NamedNode
export const AuthenticatorConfig: NamedNode = ns('AuthenticatorConfig');
export const secretKey: NamedNode = ns('secretKey');
export const secretKeyQR: NamedNode = ns('secretKeyQR');
export const hasAuthenticatorEnabled: NamedNode = ns('hasAuthenticatorEnabled');
export const hasAuthenticatorEnabledBiometric: NamedNode = ns(
  'hasAuthenticatorEnabledBiometric',
);
export const account: NamedNode = ns('account');

//An extra grouping object so all the entities can be accessed from the prefix/name
export const lincdAuthenticator = {
  AuthenticatorConfig,
  secretKeyQR,
  secretKey,
  hasAuthenticatorEnabled,
  hasAuthenticatorEnabledBiometric,
  account,
};

//Registers this ontology to LINCD.JS, so that data loading can be automated amongst other things
linkedOntology(
  _this,
  ns,
  'lincd-authenticator',
  loadData,
  '../data/lincd-authenticator.json',
);
