import React from 'react';
import './PrivacyPolicy.scss';
import style from './PrivacyPolicy.scss.json';
import {useNavigate} from 'react-router-dom';
import {DefaultLayout} from '../layout/DefaultLayout';

const PrivacyPolicy = () => {
  const navigate = useNavigate();

  const handleback = () => {
    navigate(-1);
  };

  return (
    <DefaultLayout
      title="Privacy Policy"
      showFooter={false}
      showHeader={true}
      showBackButton={true}
      colorHeader={'black'}>
      <div className={style.privacyPolicyContainer}>
        {/*<h1 dir="ltr">Privacy Policy</h1>*/}
        <p dir="ltr">
          This Privacy Policy (the <b>“Policy”</b>) governs the manner in which
          the Platform collects, uses, maintains and discloses information of
          its users. The Policy also describes the practices that We apply to
          such user information, user’s privacy rights and choices regarding
          their information. To clarify, this Policy applies to all users of the
          Platform (referred to as <b>“Users”</b>, <b>“You”</b>, <b>“Your”</b>).
        </p>
        <p dir="ltr">
          By accessing and using the Platform, providing Your Personal
          Information, or by otherwise signaling Your agreement when the option
          is presented to You, You consent to the collection, use, and
          disclosure of information described in this Policy and Terms of Use
          and we disclaim all the liabilities arising therefrom. If You do not
          agree with any provisions of this Policy and/or the Terms of Use, You
          should not access or use the Platform or engage in communications with
          us and are required to leave the Platform immediately. If any
          information You have provided or uploaded on the Platform violates the
          terms of this Policy or Terms of Use, we may be required to delete
          such information upon informing You of the same and revoke Your access
          if required without incurring any liability to You. Capitalized terms
          used but not defined in this Privacy Policy can be found in our Terms
          of Use. Please read this Policy carefully prior to accessing our
          Platform and using any of the services or products offered therein. If
          you have any questions, please contact us at the contact information
          provided below.
        </p>
        <ol>
          <li dir="ltr">
            <p>
              <b>PERSONAL INFORMATION</b>
            </p>
            <p>
              <b>“Personal Information”</b> shall mean the information which
              identifies a User i.e., first and last name, identification
              number, email address, age, gender, location, photograph and/or
              phone number provided at the time of registration or any time
              thereafter on the Platform.{' '}
            </p>
            <p>
              <b>“Sensitive Personal Information”</b> shall include (i)
              passwords and financial data (except the truncated last four
              digits of credit/debit card), (ii) health data, (iii) official
              identifier (such as biometric data, aadhar number, social security
              number, driver’s license, passport, etc.,), (iv) information about
              sexual life, sexual identifier, race, ethnicity, political or
              religious belief or affiliation, (v) account details and
              passwords, or (vi) other data/information categorized as
              ‘sensitive personal data’ or ‘special categories of data’ under
              the Information Technology (Reasonable Security Practices and
              Procedures and Sensitive Personal Data or Information) Rules,
              2011, General Data Protection Regulation (GDPR) and / or the
              California Consumer Privacy Act (CCPA){' '}
              <b>(“Data Protection Laws”)</b> and in context of this Policy or
              other equivalent / similar legislations.{' '}
            </p>
            <p>
              Usage of the term <b>‘Personal Information’</b> shall include{' '}
              <b>‘Sensitive Personal Information’</b> as may be applicable to
              the context of its usage.{' '}
            </p>
          </li>
          <li dir="ltr">
            <p dir="ltr">
              <b>INFORMATION WE COLLECT:</b>
              We may collect both personal and non-personal identifiable
              information from You in a variety of ways, including, but not
              limited to, when You visit our Platform, register on the Platform,
              and in connection with other activities, services, features or
              resources we make available on our Platform.
            </p>
          </li>
          <ol type="a" className={style.listBullet}>
            <li dir="ltr">
              <p dir="ltr">
                <b>Personal Identifiable Information:</b> We may collect
                personal-identifiable information such as Your name and emails
                address to enable Your access to the Platform and
                services/products offered therein. We will collect
                personal-identifiable information from You only if such
                information is voluntarily submitted by You to us. You can
                always refuse to provide such personal identification
                information; however, it may prevent You from accessing services
                or products provided on the Platform or from engaging in certain
                activities on the Platform.
              </p>
            </li>
            <li dir="ltr">
              <p dir="ltr">
                <b>Non-Personal Identifiable Information:</b> When You interact
                with our Platform, we may collect non-personal-identifiable
                information such as the browser name, language preference,
                referring site, and the date and time of each user request,
                operating system and the Internet service providers utilized and
                other similar information.
              </p>
            </li>
            <li dir="ltr">
              <p dir="ltr">
                <b>Cookies:</b> To enhance User experience, our Platform may use
                'cookies'. A cookie is a string of information that a website
                stores on a visitor’s computer, and that the visitor’s browser
                provides to the website each time the visitor returns for
                record-keeping purposes. You may choose to set Your web browser
                to refuse cookies, or to notify You when cookies are being sent;
                however, please note that in doing so, some parts of the
                Platform may not function properly.
              </p>
            </li>
          </ol>
          <li dir="ltr">
            <p dir="ltr">
              <b>HOW WE USE and SHARE THE INFORMATION COLLECTED</b>
            </p>
            <p>
              We may collect and use Your Personal Information for the following
              purposes:
            </p>
          </li>
          <ol type="a" className={style.listBullet}>
            <li dir="ltr">
              <p dir="ltr">
                <b>
                  To administer your account and provide our services to you
                </b>
              </p>
            </li>
            <ol type="a" className={style.listBullet}>
              <li dir="ltr">
                <p dir="ltr">Create and manage your account</p>
              </li>
              <li dir="ltr">
                <p dir="ltr">
                  Provide you with customer support and respond to your requests
                </p>
              </li>
              <li dir="ltr">
                <p dir="ltr">Communicate with you about our services</p>
              </li>
              <li dir="ltr">
                <p dir="ltr">Fulfil purchases through the Platform</p>
              </li>
              <li dir="ltr">
                <p dir="ltr">
                  Personalize pricing, offer discounts and other promotions, and
                  complete your transactions
                </p>
              </li>
              <li dir="ltr">
                <p dir="ltr">Administer sweepstakes and contests</p>
              </li>
            </ol>
            <li dir="ltr">
              <p dir="ltr">
                <b>To help you connect with other users</b>
              </p>
            </li>
            <ol type="a" className={style.listBullet}>
              <li dir="ltr">
                <p dir="ltr">
                  Recommend you to other members and recommend other members to
                  you
                </p>
              </li>
              <li dir="ltr">
                <p dir="ltr">Show members’ profiles to one another</p>
              </li>
              <li dir="ltr">
                <p dir="ltr">
                  Enable members to search for and connect with members
                </p>
              </li>
            </ol>
            <li dir="ltr">
              <p dir="ltr">
                <b>To provide new Emergent Lives services to you</b>
              </p>
            </li>
            <ol type="a" className={style.listBullet}>
              <li dir="ltr">
                <p dir="ltr">
                  Register you and display your profile on new Tinder features
                  and apps
                </p>
              </li>
              <li dir="ltr">
                <p dir="ltr">
                  Administer your account on these new features and apps
                </p>
              </li>
            </ol>
            <li dir="ltr">
              <p dir="ltr">
                <b>
                  To provide offers and operate advertising and marketing
                  campaigns
                </b>
              </p>
            </li>
            <ol type="a" className={style.listBullet}>
              <li dir="ltr">
                <p dir="ltr">
                  Perform and measure the effectiveness of advertising campaigns
                  on our services and marketing our services off our platform
                </p>
              </li>
              <li dir="ltr">
                <p dir="ltr">
                  Communicate with you about products or services that we
                  believe may interest you
                </p>
              </li>
            </ol>
            <li dir="ltr">
              <p dir="ltr">
                <b>To improve our services and develop new ones</b>
              </p>
            </li>
            <ol type="a" className={style.listBullet}>
              <li dir="ltr">
                <p dir="ltr">
                  Administer focus groups, market studies and surveys
                </p>
              </li>
              <li dir="ltr">
                <p dir="ltr">
                  Review interactions with customer care teams to improve our
                  quality of service
                </p>
              </li>
              <li dir="ltr">
                <p dir="ltr">
                  Understand how members typically use the services to improve
                  them (for instance, we may decide to change the look and feel
                  or even substantially modify a given feature based on how
                  members react to it)
                </p>
              </li>
              <li dir="ltr">
                <p dir="ltr">
                  Develop new features and services (for example, we may decide
                  to build a new interests-based feature further to requests
                  received from members)
                </p>
              </li>
            </ol>
            <li dir="ltr">
              <p dir="ltr">
                <b>
                  To prevent, detect and fight fraud and other illegal or
                  unauthorized activities
                </b>
              </p>
            </li>
            <ol type="a" className={style.listBullet}>
              <li dir="ltr">
                <p dir="ltr">
                  Find and address ongoing, suspected or alleged violations of
                  our Terms of Use, notably through the review of reports and
                  interactions between members.
                </p>
              </li>
              <li dir="ltr">
                <p dir="ltr">
                  Better understand and design countermeasures against
                  violations of our Terms of Use
                </p>
              </li>
              <li dir="ltr">
                <p dir="ltr">
                  Retain data related to violations of our Terms of Use to
                  address the violation and prevent against recurrences
                </p>
              </li>
              <li dir="ltr">
                <p dir="ltr">
                  Enforce or exercise our rights, for example our rights set out
                  in our Terms of Use
                </p>
              </li>
              <li dir="ltr">
                <p dir="ltr">
                  Communicate to individuals who submit a report, including what
                  we’ve done as a result of their submission
                </p>
              </li>
            </ol>
            <li dir="ltr">
              <p dir="ltr">
                <b>To ensure legal compliance</b>
              </p>
            </li>
            <ol type="a" className={style.listBullet}>
              <li dir="ltr">
                <p dir="ltr">Comply with legal requirements</p>
              </li>
              <li dir="ltr">
                <p dir="ltr">Assist law enforcement</p>
              </li>
            </ol>
            <p dir="ltr">
              We do not use Personal Information for making any automated
              decisions affecting or creating profiles other than what is
              described in this Policy.
            </p>
            <p>
              We do not sell, trade, or otherwise exploit Your
              personal-identifiable information to others. Limited to the
              purposes stated hereinabove, we may share generic aggregated
              demographic information not linked to any personal-identifiable
              information regarding visitors and Users with our business
              partners and trusted affiliates.
            </p>
          </ol>
          <li dir="ltr">
            <p dir="ltr">
              <b>YOUR CHOICES:</b>
            </p>
          </li>
          <ol type="a" className={style.listBullet}>
            <li dir="ltr">
              <p dir="ltr">
                <b>Limit the information You provide:</b> You always have an
                option to choose the information You provide to us, including
                the option to update or delete Your information. However, please
                note that lack of certain information may not allow You access
                to the Platform or any of its features, in part or in full. For
                example: information required for Your registration on the
                Platform.
              </p>
            </li>
            <li dir="ltr">
              <p dir="ltr">
                <b>Limit the communications You receive from us:</b> Further,
                You will also have the option to choose what kind of
                communication You would like to receive from us. However, there
                may be certain communications that are required for legal or
                security purposes, including changes to various legal
                agreements, that you may not be able to limit.
              </p>
            </li>
            <li dir="ltr">
              <p dir="ltr">
                <b>Reject Cookies and other similar technologies:</b> You may
                reject or remove cookies from Your web browser; You will always
                have the option to change the default settings on Your web
                browser if the same is set to ‘accept cookies’. However, please
                note that some of the services/products offered on the Platform
                may not function or be available to You, when the cookies are
                rejected, removed or disabled.
              </p>
            </li>
          </ol>
          <li dir="ltr">
            <p dir="ltr">
              <b>YOUR RIGHTS:</b> <br />
              <br />
              In general, all Users have the rights specified herein this
              section. However, depending on where you are situated, you may
              have certain specific rights in respect of your Personal
              Information accorded by the laws of the country you are situated
              in. To understand <b>Your rights</b>, please refer to the{' '}
              <b>Country Specific Additional Rights</b> below. <br />
              <br />
              If you are a User, you may exercise any of these rights by using
              the options provided to you within the Platform upon your login.
              If however, you are facing any issues or require any
              clarifications, you can always write to us at the address noted in
              the <b>‘Grievances’</b>
              section below, and we will address your concerns to the extent
              required by the applicable law.
            </p>
          </li>
          <ol type="a" className={style.listBullet}>
            <li dir="ltr">
              <p dir="ltr">
                <b>Right to Confirmation and Access:</b> You have the right to
                get confirmation and access to your Personal Information that is
                with us along with other supporting information.
              </p>
            </li>
            <li dir="ltr">
              <p dir="ltr">
                <b>Right to Correction:</b> You have the right to ask us to
                rectify your Personal Information that is with us that you think
                is inaccurate. You also have the right to ask us to update your
                Personal Information that you think is incomplete or
                out-of-date.
              </p>
            </li>
            <li dir="ltr">
              <p dir="ltr">
                <b>Right to be Forgotten:</b> You have the right to restrict or
                prevent the continuing disclosure of your Personal Information
                under certain circumstances.
              </p>
            </li>
            <li dir="ltr">
              <p dir="ltr">
                <b>Right to Erasure:</b> If you wish to withdraw/remove your
                Personal Information from our Platform, you have the right to
                request erasure of your Personal Information from our Platform.
                However, please note that such erasure will remove all your
                Personal Information from our Platform (except as specifically
                stated in this Policy) and may result in deletion of your
                account on the Platform permanently, and the same will not be
                retrievable.
              </p>
            </li>
          </ol>
          <li dir="ltr">
            <p dir="ltr">
              Remember, you are entitled to exercise your rights as stated above
              only with respect to your information, including Personal
              Information, and not that of other Users. Further, when we receive
              any requests or queries over email to the address specified in the
              ‘Grievances’ section below, then, as per the applicable Data
              Protection Laws, we may need to ask you a few additional
              information to verify your identity in association with the
              Platform and the request received.
            </p>
          </li>
          <li dir="ltr">
            <p dir="ltr">
              <b>PROTECTION OF YOUR INFORMATION:</b>
              <br />
              <br />
              We take all measures reasonably necessary to protect against the
              unauthorized access, use, alteration or destruction of Personal
              Information or such other data on the Platform. Our disclosure of
              any such information is limited to – <br />
            </p>
          </li>
          <ol type="a" className={style.listBullet}>
            <li dir="ltr">
              <p dir="ltr">
                our employees, contractors and affiliated organizations (if any)
                that (i) need to know that information in order to process it on
                our behalf or to provide services available on our Platform, and
                (ii) that have agreed not to disclose it to others.
              </p>
            </li>
            <li dir="ltr">
              <p dir="ltr">
                a response to a court order, or other governmental request.
                Without imitation to the foregoing, we reserve the right to
                disclose such information where we believe in good faith that
                such disclosure is necessary to –
              </p>
            </li>
            <ol type="a" className={style.listBullet}>
              <li dir="ltr">
                <p dir="ltr">
                  comply with applicable laws, regulations, court orders,
                  government and law enforcement agencies’ requests;
                </p>
              </li>
              <li dir="ltr">
                <p dir="ltr">
                  protect and defend a third party's or our rights and property,
                  or the safety of our users, our employees, or others; or
                </p>
              </li>
              <li dir="ltr">
                <p dir="ltr">
                  prevent, detect, investigate and take measures against
                  criminal activity, fraud and misuse or unauthorized use of our
                  Platform and/or to enforce our Terms of Use or other
                  agreements or policies.
                </p>
              </li>
            </ol>
            <li dir="ltr">
              <p dir="ltr">
                To the extent permitted by law, we will attempt to give You
                prior notice before disclosing Your information in response to
                such a request.
              </p>
            </li>
          </ol>
          <li dir="ltr">
            <p dir="ltr">
              <b>THIRD PARTY WEBSITES</b> <br />
              <br />
              You may find links to the websites and services of our partners,
              suppliers, advertisers, sponsors, licensors and other third
              parties. The content or links that appear on these sites are not
              controlled by us in any manner and we are not responsible for the
              practices employed by such websites. Further, these websites/links
              thereto, including their content, may be constantly changing and
              the may have their own terms of use and privacy policies. Browsing
              and interaction on any other website, including websites which
              have a link to our Site, is subject to that terms and policies
              published on such websites.
            </p>
          </li>
          <li dir="ltr">
            <p dir="ltr">
              <b>CROSS-BORDER DATA TRANSFER </b>
              <br />
              <br />
              Your information including any Personal Information is stored,
              processed, and transferred in and to the Amazon Web Service (AWS)
              servers and databases located in India. We may also store,
              process, and transfer information in and to servers in other
              countries depending on the location of our affiliates and service
              providers.
              <br />
              <br />
              Please note that these countries may have differing (and
              potentially less stringent) privacy laws and that Personal
              Information can become subject to the laws and disclosure
              requirements of such countries, including disclosure to
              governmental bodies, regulatory agencies, and private persons, as
              a result of applicable governmental or regulatory inquiry, court
              order or other similar process. <br />
              <br />
              If you use our Platform from outside India, including in the USA,
              EU, EEA, and UK, your information may be transferred to, stored,
              and processed in India. By accessing our Platform or otherwise
              giving us information, you consent to the transfer of information
              to India and other countries outside your country of residence.
            </p>
          </li>
          <li dir="ltr">
            <p dir="ltr">
              <b>DURATION FOR WHICH YOUR INFORMATION IS STORED </b>
              <br />
              <br />
              We will retain Your information for as long as it is required for
              us to retain for the purposes stated hereinabove, including for
              the purpose of complying with legal obligation or business
              compliances.
              <br />
              <br />
              Further, please note that we may not be able to delete all
              communications or photos, files, or other documents publicly made
              available by you on the Platform (for example: comments, feedback,
              etc.), however, we shall anonymize your Personal Information in
              such a way that you can no longer be identified as an individual
              in association with such information made available by you on the
              Platform. We will never disclose aggregated or de-identified
              information in a manner that could identify you as an individual.
              <br />
              <br />
              Note: If you wish to exercise any of your rights (as specified in
              <b>‘Your Rights’</b> section below) to access, modify and delete
              any or all information stored about you, then you may do so by
              using the options provided within the Platform. You can always
              write to us at the email address specified in the ‘Grievances’
              section below
            </p>
          </li>
          <li dir="ltr">
            <p dir="ltr">
              <b>MODIFICATION TO PRIVACY POLICY:</b> <br />
              <br />
              We may modify, revise or change our Policy from time to time; when
              we do, we will revise the ‘updated date’ at the beginning of this
              page. We encourage You to check our Platform frequently to see the
              recent changes. Unless stated otherwise, our current Policy
              applies to all information that we have about You.
            </p>
          </li>
          <li dir="ltr">
            <p dir="ltr">
              <b>GRIEVANCES:</b> <br />
              If you have any questions about this Policy, wish to exercise your
              rights, concerns about privacy or grievances, please write to us
              with a thorough description via email to
              team@emergentlives.com.
            </p>
          </li>
        </ol>
        <h3 dir="ltr">
          <b>COUNTRY SPECIFIC ADDITIONAL RIGHTS</b>
        </h3>
        <ol>
          <li dir="ltr">
            <p dir="ltr">
              <b>TERMS APPLICABLE IF YOU ARE AN INDIAN RESIDENT</b> <br />
              <br />
              <b>Your Rights:</b> If you are located in India, you may have the
              following rights under the Personal Data Protection Bill (PDPB)
              when it becomes a legislation. All requests can be made by using
              the option provided to you within the Platform upon your login.
              You may also write to us as stated in the <b>“Grievances”</b>{' '}
              section above, and we will address you concerns to the extent
              required by law. <br />
            </p>
          </li>
          <ol type="a" className={style.listBullet}>
            <li dir="ltr">
              <p dir="ltr">
                <b>Right to Confirmation and Access:</b> You have the right to
                get confirmation and access to your Personal Information that is
                with us along with other supporting information.
              </p>
            </li>
            <li dir="ltr">
              <p dir="ltr">
                <b>Right to Correction:</b> You have the right to ask us to
                rectify your Personal Information that is with us that you think
                is inaccurate. You also have the right to ask us to update your
                Personal Information that you think is incomplete or
                out-of-date.
              </p>
            </li>
            <li dir="ltr">
              <p dir="ltr">
                <b>Right to Data Portability:</b> You have the right to ask that
                we transfer the Personal Information you gave us to another
                organisation, or to you, under certain circumstances.
              </p>
            </li>
            <li dir="ltr">
              <p dir="ltr">
                <b>Right to be Forgotten:</b> You have the right to restrict or
                prevent the continuing disclosure of your Personal Information
                under certain circumstances.
              </p>
            </li>
            <li dir="ltr">
              <p dir="ltr">
                <b>Right to Erasure:</b> If you wish to withdraw/remove your
                Personal Information from our Platform, you have the right to
                request erasure of your Personal Information from our Platform.
                However, please note that such erasure will remove all your
                Personal Information from our Platform (except as specifically
                stated in this Policy) and may result in deletion of your
                account on the Platform permanently, and the same will not be
                retrievable.
              </p>
            </li>
          </ol>
          <li dir="ltr">
            <p dir="ltr">
              <b>
                TERMS APPLICABLE IF YOU ARE A RESIDENT OF UNITED KINGDOM (UK), A
                EUROPEAN UNION (EU) COUNTRY OR EUROPEAN ECONOMIC AREA (EEA)
              </b>
              <br />
              <br />
              <b>Your Rights:</b> If you are located in the United Kingdom (UK)
              or European Union (EU) or European Economic Area (EEA), you have
              the following rights under the UK and EU General Data Protection
              Regulation (GDPR) respectively. All requests should be sent to the
              address noted in the <b>“Grievances”</b> section above, and we
              will fulfil requests to the extent required by applicable law.
            </p>
          </li>
          <ol type="a" className={style.listBullet}>
            <li dir="ltr">
              <p dir="ltr">
                <b>Right to access Your Personal Information:</b> You have the
                right to receive confirmation as to whether or not Personal
                Information concerning you is being processed and, where that is
                the case, access to the Personal Information can be sought;
              </p>
            </li>
            <li dir="ltr">
              <p dir="ltr">
                <b>Right to Rectification:</b> Our goal is to keep your Personal
                Information accurate, current and complete. Please contact us if
                you believe your information is inaccurate or incomplete;
              </p>
            </li>
            <li dir="ltr">
              <p dir="ltr">
                <b>Right to Erasure:</b> In some cases, you have a legal right
                to request that we erase your Personal Information;
              </p>
            </li>
            <li dir="ltr">
              <p dir="ltr">
                <b>Right to object to Processing:</b> You have the right to
                object to our processing of your Personal Information under
                certain conditions;
              </p>
            </li>
            <li dir="ltr">
              <p dir="ltr">
                <b>Right to restrict Processing:</b> You have the right to
                request that we restrict the processing of your Personal
                Information, under certain conditions;
              </p>
            </li>
            <li dir="ltr">
              <p dir="ltr">
                <b>Right to Data Portability:</b> You have the right to request
                that we transfer the data that we have collected to another
                organization, or directly to you, under certain conditions;
              </p>
            </li>
            <li dir="ltr">
              <p dir="ltr">
                <b>
                  Right to make a complaint to a government supervisory
                  authority:
                </b>
                If you believe we have not processed your Personal Information
                in accordance with applicable provisions of the GDPR, we
                encourage you to contact us at email address provided in the
                ‘Grievances’ section above. You also have the right to make a
                GDPR complaint to the relevant Supervisory Authority or seek a
                remedy through the courts. A list of Supervisory Authorities is
                available at:{' '}
                <a href="https://edpb.europa.eu/about-edpb/board/members_en">
                  https://edpb.europa.eu/about-edpb/board/members_en
                </a>
                . If you need further assistance regarding your rights, please
                contact us using the contact information provided below, and we
                will consider your request in accordance with applicable law.
                You can identify the supervising authority of your concern by
                visiting{' '}
                <a href="https://edpb.europa.eu/about-edpb/board/members_en">
                  https://edpb.europa.eu/about-edpb/board/members_en
                </a>
                .
              </p>
            </li>
            <li dir="ltr">
              <p dir="ltr">
                <b>
                  Right to not be subject to automated decision-making,
                  including profiling:
                </b>{' '}
                You have the right not to be subject to a decision based solely
                on automated processing, including profiling, which produces
                legal or similarly significant effects concerning you.
              </p>
            </li>
          </ol>
          <li dir="ltr">
            <p dir="ltr">
              We collect and process Personal Information about you only where
              we have a legal rationale to do so. Specific legal rationale
              applied for the same will depend on the type of Personal
              Information collected and the context in which the same is being
              processed, including the Services involved. <br />
              <br />
            </p>
          </li>
          <li dir="ltr">
            <p dir="ltr">
              <b>TERMS APPLICABLE IF YOU ARE A CALIFORNIA STATE RESIDENT</b>{' '}
              <br />
              <br />
              If you are a California state resident, then you have the
              following rights to the extent, and in the manner, set out in the
              CCPA: <br />
            </p>
          </li>
          <ol type="a" className={style.listBullet}>
            <li dir="ltr">
              <p dir="ltr">
                The right to access the Personal Information that we hold on
                you;
              </p>
            </li>
            <li dir="ltr">
              <p dir="ltr">
                The right to know what Personal Information we intend on
                collecting from them before the point of collection;
              </p>
            </li>
            <li dir="ltr">
              <p dir="ltr">
                The right to opt in or out of marketing, analytics, and other
                similar activities;
              </p>
            </li>
            <li dir="ltr">
              <p dir="ltr">
                The right to equal services without discrimination; and
              </p>
            </li>
            <li dir="ltr">
              <p dir="ltr">
                The right to request deletion of Personal Information.
              </p>
            </li>
          </ol>
          <li dir="ltr">
            <p dir="ltr">
              The above rights, the manner in which you can exercise the same
              and the category of and the manner in which we collect your
              information, are detailed below.
            </p>
          </li>
        </ol>
        <ol>
          <li dir="ltr">
            <p dir="ltr">
              <b>CCPA NOTICE AT COLLECTION:</b> <br />
              <br />
              For purposes of the CCPA, in collecting the information described
              above, we collect the categories of Personal Information listed
              below from you: <br />
            </p>
          </li>
          <ol>
            <li dir="ltr">
              <p dir="ltr">
                <b>Identifiers:</b> We may collect your name, email address,
                mobile number, username, unique personal identifier, and
                Internet Protocol (IP) address. We use Identifiers as set forth
                in the “How We Use and Share the Information Collected” section
                of this Policy, like your name, contact information, and device
                and online identifiers.
              </p>
            </li>
            <li dir="ltr">
              <p dir="ltr">
                <b>
                  Characteristics of Personal Information described in the
                  California Customer Records statute:
                </b>{' '}
                We may collect your name, email address, username, unique
                personal identifier, and gender. We use Categories of Personal
                Information described in the California Consumer Records statute
                as set forth in the{' '}
                <b>‘How We Use and Share the Information Collected’</b> section
                of this Policy.
              </p>
            </li>
            <li dir="ltr">
              <p dir="ltr">
                <b>
                  Internet or other electronic network activity information:
                </b>{' '}
                We collect cookies as described above, we will automatically
                receive information from your browser and your device, which
                includes the date and time of your visit to the Platform as well
                as your location, Internet Protocol (IP) address, domain server,
                browser type, access time, and data about which pages you visit
                on the Platform. We use Internet or other electronic network
                activity information as set forth in the “How We Use and Share
                the Information Collected” section of this Policy.
              </p>
            </li>
            <li dir="ltr">
              <p dir="ltr">
                <b>Geolocation data:</b> We may collect your IP address. We may
                use Geolocation Data as set forth in the “How We Use and Share
                the Information Collected” section of this Policy.
              </p>
            </li>
            <li dir="ltr">
              <p dir="ltr">
                <b>Audio, electronic, visual or similar information:</b> We may
                collect your profile picture or other audio or visual
                information uploaded as content to the Platform. We use audio,
                electronic, visual or similar information as set forth in the
                “How We Use and Share the Information Collected” section of this
                Policy.
              </p>
            </li>
            <li dir="ltr">
              <p dir="ltr">
                <b>Inferences:</b> We may make inferences based upon the
                Personal Information collected (such as likelihood of retention
                or attrition). We use Inference information as set forth in the
                “How We Use and Share the Information Collected” section of this
                Policy.
              </p>
            </li>
          </ol>
          <li dir="ltr">
            <p dir="ltr">
              <b>CCPA DATA PRACTICES DURING THE LAST 12 MONTHS:</b> <br />
              <br />
            </p>
          </li>
          <ol className={style.listBullet} type="a">
            <li dir="ltr">
              <p dir="ltr">
                <b>Personal Information collected:</b> As described in this
                Policy, we have collected the categories of Personal Information
                listed below during the preceding 12 months:
              </p>
            </li>
            <ol className={style.listBullet} type="a">
              <li dir="ltr">
                <p dir="ltr">Identifiers</p>
              </li>
              <li dir="ltr">
                <p dir="ltr">
                  Characteristics of Personal Information described in the
                  California Customer Records statute
                </p>
              </li>
              <li dir="ltr">
                <p dir="ltr">
                  Internet or other electronic network activity information
                </p>
              </li>
              <li dir="ltr">
                <p dir="ltr">Geolocation data</p>
              </li>
              <li dir="ltr">
                <p dir="ltr">Commercial information</p>
              </li>
              <li dir="ltr">
                <p dir="ltr">
                  Audio, electronic, visual, thermal, olfactory, or similar
                  information Inferences
                </p>
              </li>
            </ol>
            <li dir="ltr">
              <p dir="ltr">
                <b>Categories of sources:</b> We have collected the Personal
                Information identified in this Policy from you and our payment
                processors.
              </p>
            </li>
            <li dir="ltr">
              <p dir="ltr">
                <b>Business and commercial purposes for collecting:</b> We have
                collected the categories of Personal Information listed above
                for the following purposes:
              </p>
            </li>
            <ol className={style.listBullet} type="a">
              <li dir="ltr">
                <p dir="ltr">Operate the Platform;</p>
              </li>
              <li dir="ltr">
                <p dir="ltr">Provide our Services to you;</p>
              </li>
              <li dir="ltr">
                <p dir="ltr">Honor our Terms and Conditions and contracts;</p>
              </li>
              <li dir="ltr">
                <p dir="ltr">
                  Ensure the privacy and security of our Platform and Services;
                </p>
              </li>
              <li dir="ltr">
                <p dir="ltr">Manage our relationships with you;</p>
              </li>
              <li dir="ltr">
                <p dir="ltr">Communicate with you;</p>
              </li>
              <li dir="ltr">
                <p dir="ltr">Analyze use of the Platform and our Services;</p>
              </li>
              <li dir="ltr">
                <p dir="ltr">Enhance your experience;</p>
              </li>
              <li dir="ltr">
                <p dir="ltr">Track visits to the Platform;</p>
              </li>
              <li dir="ltr">
                <p dir="ltr">
                  Provide you with a more personal and interactive experience on
                  the Platform; and
                </p>
              </li>
              <li dir="ltr">
                <p dir="ltr">Usage analytics purposes.</p>
              </li>
            </ol>
            <li dir="ltr">
              <p dir="ltr">
                <b>Personal Information sold:</b> We have not sold categories of
                Personal Information during the preceding 12 months.
              </p>
            </li>
            <li dir="ltr">
              <p dir="ltr">
                <b>Personal Information disclosed for a business purpose:</b>We
                have disclosed for a business purpose the categories of Personal
                Information listed below during the preceding 12 months:
              </p>
            </li>
            <ol className={style.listBullet} type="a">
              <li dir="ltr">
                <p dir="ltr">Identifiers</p>
              </li>
              <li dir="ltr">
                <p dir="ltr">
                  Characteristics of Personal Information described in the
                  California Customer Records statute
                </p>
              </li>
              <li dir="ltr">
                <p dir="ltr">
                  Internet or other electronic network activity information
                </p>
              </li>
              <li dir="ltr">
                <p dir="ltr">Geolocation data</p>
              </li>
              <li dir="ltr">
                <p dir="ltr">Commercial information</p>
              </li>
              <li dir="ltr">
                <p dir="ltr">
                  Audio, electronic, visual, thermal, olfactory, or similar
                  information Inferences
                </p>
              </li>
            </ol>
            <li dir="ltr">
              <p dir="ltr">
                We have disclosed each category of Personal Information to the
                following categories of third parties: (1) corporate parents,
                subsidiaries, and affiliates; (2) advisors (accountants,
                attorneys); (3) service providers (data analytics, data storage,
                mailing, marketing, website and platform administration,
                technical support); and (4) operating systems and platforms.
              </p>
            </li>
          </ol>
          <li dir="ltr">
            <p dir="ltr">
              <b>CONSUMER RIGHTS AND REQUESTS UNDER THE CCPA</b> <br />
              <br />
              The CCPA gives consumers the right to request that we (1) disclose
              what Personal Information we collect, use, disclose, and sell, and
              (2) delete certain Personal Information that we have collected or
              maintained. you may submit these requests to us as described
              below, and we honor these rights where they apply. <br />
              <br />
              If a request is submitted in a manner that is not one of the
              designated methods for submission, or if the request is deficient
              in some manner unrelated to our verification process, we will
              either (i) treat the request as if it had been submitted in
              accordance with the designated manner, or (ii) provide you with
              specific directions on how to submit the request or remedy any
              deficiencies with the request, as applicable. <br />
            </p>
          </li>
          <ol className={style.listBullet} type="a">
            <li dir="ltr">
              <p dir="ltr">
                <b>Request to Know:</b> As a California resident, you have the
                right to request: (1) the specific pieces of Personal
                Information we have collected about you; (2) the categories of
                Personal Information we have collected about you; (3) the
                categories of sources from which the Personal Information is
                collected; (4) the categories of Personal Information about you
                that we have sold and the categories of third parties to whom
                the Personal Information was sold; (5) the categories of
                Personal Information about you that we disclosed for a business
                purpose and the categories of third parties to whom the Personal
                Information was disclosed for a business purpose; (6) the
                business or commercial purpose for collecting, disclosing, or
                selling Personal Information; and (7) the categories of third
                parties with whom we share Personal Information. Our response
                will cover the 12-month period preceding our receipt of a
                verifiable request.
              </p>
            </li>
            <li dir="ltr">
              <p dir="ltr">
                <b>Request to Delete:</b> As a California resident, you have a
                right to request the erasure/deletion of certain Personal
                Information collected or maintained by us. As described herein,
                we will delete your Personal Information from our records and
                direct any service providers (as defined under applicable law)
                to delete your Personal Information from their records. However,
                we are not required to honor a deletion request if an exemption
                applies under the law.
              </p>
            </li>
            <li dir="ltr">
              <p dir="ltr">
                <b>Submitting a Request:</b> <br />
              </p>
            </li>
            <ol className={style.listBullet} type="a">
              <li dir="ltr">
                <p dir="ltr">
                  Submission of Instructions: You may submit a request to know
                  or to delete by email to the address provided in the
                  ‘Grievances’ section above or by submitting a request via mail
                  to address provided by us in our Terms of Use or the Platform
                  page. Regarding requests to delete, we may present you with
                  the choice to delete select portions of your Personal
                  Information, but a global option to delete all Personal
                  Information will be offered and more prominently presented.
                </p>
              </li>
              <li dir="ltr">
                <p dir="ltr">
                  Verification Process: We are required by law to verify the
                  identities of those who submit requests to know or to delete.
                  To determine whether the individual making the request is the
                  consumer about whom we have collected information, we will
                  verify your identity by matching the identifying information
                  provided by you in the request to the Personal Information
                  that we already maintain about you. As a part of this process,
                  you will be required to provide your name and email address,
                  and mobile number (if the same have been voluntarily provided
                  by you at the time of registering on the Platform or otherwise
                  while using the Platform). We will inform you if we cannot
                  verify your identity. Please note- <br />
                </p>
              </li>
              <ol className={style.listBullet} type="a">
                <li dir="ltr">
                  <p dir="ltr">
                    If we cannot verify the identity of the person making a
                    request for categories of Personal Information, we may deny
                    the request. If the request is denied in whole or in part
                    for this reason, we will provide a copy of, or direct you
                    to, our Privacy Policy.
                  </p>
                </li>
                <li dir="ltr">
                  <p dir="ltr">
                    If we cannot verify the identity of the person making the
                    request for specific pieces of Personal Information, we are
                    prohibited from disclosing any specific pieces of Personal
                    Information to the requestor. However, if denied in whole or
                    in part for this reason, we will evaluate the request as if
                    it is seeking the disclosure of categories of Personal
                    Information about the consumer.
                  </p>
                </li>
                <li dir="ltr">
                  <p dir="ltr">
                    If we cannot verify the identity of the person making a
                    request to delete, we may deny the request. If there is no
                    reasonable method by which we can verify the identity of the
                    requestor to the degree of certainty required, we will state
                    this in our response and explain why we have no reasonable
                    method by which we can verify the identity of the requestor.
                  </p>
                </li>
              </ol>
              <li dir="ltr">
                <p dir="ltr">
                  Authorized Agents: Authorized agents may submit requests via
                  the methods identified in this Policy. If you use an
                  authorized agent to submit a request to know or a request to
                  delete, we may require you to: (1) provide the authorized
                  agent with signed permission to do so; (2) verify your
                  identity directly with us; and (3) directly confirm with us
                  that you provided the authorized agent permission to submit
                  the request. However, we will not require these actions if you
                  have provided the authorized agent with power of attorney
                  pursuant to the California Probate Code.
                </p>
              </li>
              <li dir="ltr">
                <p dir="ltr">
                  Excessive Requests: If requests from a User are manifestly
                  unfounded or excessive, in particular because of their
                  repetitive character, we may either (1) charge a reasonable
                  fee, or (2) refuse to act on the request and notify the User
                  of the reason for refusing the request. If we charge a fee,
                  the amount will be based upon the administrative costs of
                  providing the information or communication or taking the
                  action requested.
                </p>
              </li>
              <li dir="ltr">
                <p dir="ltr">
                  CCPA Non-Discrimination: You have the right not to receive
                  discriminatory treatment by us due to your exercise of the
                  rights provided by the CCPA. We do not offer financial
                  incentives and price or service differences, and we do not
                  discriminate against Users/consumers for exercising their
                  rights under the CCPA.
                </p>
              </li>
            </ol>
          </ol>
        </ol>
      </div>
    </DefaultLayout>
  );
};

export default PrivacyPolicy;
