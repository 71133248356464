import React,{ReactNode} from 'react';
import './Container.scss';
import style from './Container.scss.json';

interface ContainerProps
{
  children: ReactNode;
}

export default function Container({children}: ContainerProps) {
  return <div className={style.container}>{children}</div>;
}
