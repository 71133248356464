import {NamedNode} from 'lincd/lib/models';
import {linkedShape} from '../package';
import {Event} from 'lincd-schema/lib/shapes/Event';
import {emergentLives} from '../ontologies/emergent-lives';
import {ProviderEvent} from './ProviderEvent';
import {schema} from 'lincd-schema/lib/ontologies/schema';

@linkedShape
export class ClientEvent extends Event
{
  static targetClass: NamedNode = emergentLives.ClientEvent;

  get superEvent(): ProviderEvent
  {
    return this.getOneAs(schema.superEvent,ProviderEvent);
  }

  set superEvent(event: ProviderEvent)
  {
    this.overwrite(schema.superEvent,event.node);
  }

}
