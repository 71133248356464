import React,{useEffect,useState} from 'react';
import './ShowID.scss';
import style from './ShowID.scss.json';
import {useAuth} from 'lincd-auth/lib/hooks/useAuth';
import {ClientAccount} from '../shapes/ClientAccount';
import {Person} from 'profile-plus/lib/shapes/Person';
import {ImageView} from 'lincd-schema/lib/components/ImageView';

// import {Nfc, NfcUtils, NfcTagTechType} from '@capawesome-team/capacitor-nfc';
export function ShowQR()
{
  const auth = useAuth<Person,ClientAccount>();
  const [bool,setBool] = useState(false);
  const forceUpdate = () => setBool(!bool);
  const username = auth.userAccount.accountOf as Person;
  useEffect(() => {
    if (!auth?.userAccount?.qrCode)
    {
      auth?.userAccount?.getPersonalQRCode().then(() => {
        forceUpdate();
      });
    }
  },[auth]);

  return (
    <div className={style.Container}>
      <div className={style.innerContainer}>
        <h2>Shortlink QR</h2>
        <h2>{username.givenName}</h2>
        <p className={style.businessType}>Business Card</p>
        <ImageView of={auth?.userAccount?.qrCode} width={300} height={300} />
        <p>Your contact can scan this code to start</p>
        {/*<Button>Share Code</Button>*/}
      </div>
    </div>
  );
}

// export const ShowNFC = ({reader}) => {
//   useEffect(() => {
//     // This will run whenever the 'reader' prop changes
//     console.log('New value of reader:', reader);
//   }, [reader]);
//   // const createNdefTextRecord = () => {
//   //   const utils = new NfcUtils();
//   //   const {record} = utils.createNdefTextRecord({text: 'Capacitor NFC Plugin'});
//   //   return record;
//   // };

//   // const write = async () => {
//   //   return new Promise((resolve) => {
//   //     const record = createNdefTextRecord();

//   //     Nfc.addListener('nfcTagScanned', async (event) => {
//   //       await Nfc.write({message: {records: [record]}});
//   //       await Nfc.stopScanSession();
//   //       resolve(event);
//   //       console.log(resolve(event));
//   //     });

//   //     Nfc.startScanSession();
//   //   });
//   // };
//   // write();
//   const read = async () => {
//     return new Promise((resolve) => {
//       Nfc.addListener('nfcTagScanned', async (event) => {
//         console.log('EVENT', event);
//         await Nfc.stopScanSession();
//         resolve(event.nfcTag);
//         console.log('resolved', resolve(event.nfcTag));
//       });

//       Nfc.startScanSession();
//     });
//   };
//   read();
//   return (
//     <div className={style.Container}>
//       <div className={style.innerContainer}>
//         {reader ? (
//           <>
//             <img
//               src="/images/nfcscan.gif"
//               alt="NFC Scan"
//               className={style.nfcScanner}
//             />
//             <h2 className={style.textNFC}>Scanning Near NFC Devices</h2>
//           </>
//         ) : (
//           <>
//             <h2>Instruction</h2>
//             <img
//               src={'/images/bumphone.png'}
//               alt="Phone Image"
//               className={style.bumpPhone}
//             />
//             <p>Follow this instruction to get your new contact</p>
//           </>
//         )}
//       </div>
//     </div>
//   );
// };
