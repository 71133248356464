import React, {useEffect, useState, useCallback} from 'react';
import {registerPackageModule, linkedComponent} from '../package';
import {BiometryType, NativeBiometric} from 'capacitor-native-biometric';
import {Button} from 'lincd-mui-base/lib/components/Button';
import {useAuth} from 'lincd-auth/lib/hooks/useAuth';
import {AuthenticatorConfig} from '../shapes/AuthenticatorConfig';
import './EnableAuthenticatorButton.scss';
import style from './EnableAuthenticatorButton.scss.json';
import {cl} from 'lincd/lib/utils/ClassNames';

interface Props {
  onClick?: (type: string) => void;
  onNavigate?: () => void;
}

export const EnableAuthenticatorButton = ({onClick}: Props) => {
  const auth = useAuth();
  const account = auth?.userAccount;
  const user = auth?.user;

  return (
    <>
      <Button
        className={cl(style.googleAuth)}
        endIcon={
          AuthenticatorConfig.getEnabledForAccount(account) ? (
            <img
              className={style.checkMark}
              src="/images/checkmark_green.png"
            />
          ) : (
            <img className={style.checkMark} src="/images/empty_check.png" />
          )
        }
        onClick={() => {
          onClick('Google Auth');
        }}
      >
        Authenticator
      </Button>
      <Button
        className={cl(style.googleAuth)}
        endIcon={
          AuthenticatorConfig.getEnabledForAccountBiometric(account) ? (
            <img
              className={style.checkMark}
              src="/images/checkmark_green.png"
            />
          ) : (
            <img className={style.checkMark} src="/images/empty_check.png" />
          )
        }
        onClick={() => onClick('Biometric Auth')}
      >
        Biometric Authentication
      </Button>
    </>
  );
};

type BiometricVerificationResult = {
  success: boolean;
  error?: string;
  configuration?: any; // Replace 'any' with the actual type of configuration
  account?: any; // Replace 'any' with the actual type of account
};

export const performBiometricVerification = (
  configuration,
  account,
  biometricVerificationTriggered,
): Promise<BiometricVerificationResult> => {
  // generate hash password
  const handleHashPassword = async () => {
    const encoder = new TextEncoder();
    const data = encoder.encode(account.uid);

    try {
      const hashBuffer = await crypto.subtle.digest('SHA-256', data);
      const hashArray = Array.from(new Uint8Array(hashBuffer));
      const hashHex = hashArray
        .map((byte) => byte.toString(16).padStart(2, '0'))
        .join('');
      return hashHex;
    } catch (error) {
      console.error('Error hashing password:', error);
      return null;
    }
  };

  if (biometricVerificationTriggered) {
    console.error('Biometric verification already triggered');
    return Promise.resolve({
      success: false,
      error: 'Biometric verification already triggered',
    });
  }

  return NativeBiometric.isAvailable().then((result) => {
    if (!result.isAvailable) {
      console.error('Biometric Not Available');
      return {
        success: false,
        error: 'Biometric Not Available',
      };
    }

    // Assuming verifyIdentity returns a promise
    return NativeBiometric.verifyIdentity({
      reason: 'Biometric Scan Login',
      title: 'Biometric Authentication',
      subtitle: 'Enter your biometric credential to complete.',
      maxAttempts: 3,
    })
      .then(async () => {
        // If no error is thrown, consider the biometric verification successful
        console.log('Biometric verification successful');

        // if authenticator for biometric has been setup
        const isBiometricEnabled =
          AuthenticatorConfig.getEnabledForAccountBiometric(account);
        if (isBiometricEnabled) {
          return {
            success: true,
            configuration,
            account,
            biometricVerificationTriggered,
          };
        } else {
          // Save user's credentials and setup the biometric
          const hashedPassword = await handleHashPassword();
          const setCredentialss = await NativeBiometric.setCredentials({
            username: account.email,
            password: hashedPassword,
            server: process.env.APP_NAME,
          })
            .then(() => {
              return {
                success: true,
                configuration,
                account,
                biometricVerificationTriggered,
              };
            })
            .catch((error) => {
              console.error('Biometric verification failed:', error);
              // Resolve with false if biometric verification fails
              return {
                success: false,
                error: 'Biometric verification & save authentication failed',
              };
            });
          return setCredentialss;
        }
      })
      .catch((error) => {
        // Handle specific error scenarios, if needed
        console.error('Biometric verification failed:', error);
        // Resolve with false if biometric verification fails

        return {
          success: false,
          error: 'Biometric verification failed',
        };
      });
  });
};
export const getCredentials = async () => {
  return await NativeBiometric.getCredentials({
    server: process.env.APP_NAME,
  })
    .then(() => {
      return {
        success: true,
      };
    })
    .catch((error) => {
      console.error('Authentication verification failed:', error);
      // Resolve with false if biometric verification fails
      return {
        success: false,
        error: 'Biometric verification failed',
      };
    });
};
//register all components in this file
registerPackageModule(module);
