import React from 'react';
import './Quests.scss';
import style from './Quests.scss.json';
import {DefaultLayout} from '../layout/DefaultLayout';

const FeatureComingSoon = () => {
  return (
    <DefaultLayout isHome={false} colorHeader="black" title="Quests">
      <div className={style.questContainer}>
        <h2>Exciting Feature Coming Soon!</h2>
        <p>
          We're thrilled to announce that a new and exciting feature is on its
          way to our platform.
        </p>
        <p>
          Stay tuned for updates as we work hard to bring you this feature that
          will enhance your experience.
        </p>
        <p>Thank you for your patience and continued support!</p>
        <img src="/images/rocket.gif" width="150" height="150" />
      </div>
    </DefaultLayout>
  );
};

export default FeatureComingSoon;
