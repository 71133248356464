import React, {useEffect, useState} from 'react';
import './AuthenticatorSetup.scss';
import style from './AuthenticatorSetup.scss.json';
import {packageName} from '../package';
import {Button} from 'lincd-mui-base/lib/components/Button';
import {ImageView} from 'lincd-schema/lib/components/ImageView';
import {OTPInput} from 'lincd-input/lib/components/OTPInput';
import {Server} from 'lincd-server-utils/lib/utils/Server';
import {useAuth} from 'lincd-auth/lib/hooks/useAuth';
import {AuthenticatorConfig} from '../shapes/AuthenticatorConfig';
import {useNavigate} from 'react-router-dom';

interface iProps {
  onComplete?: () => void;
  loadingComponent?: any;
}

export const AuthenticatorSetup = ({onComplete, loadingComponent}: iProps) => {
  const [isLoading, setLoading] = useState(true);
  const [showPopup, setShowPopup] = useState(false);
  const navigate = useNavigate();
  const auth = useAuth();
  let userAccount = auth?.userAccount;
  const [copyButton, setCopyButton] = useState(false);

  useEffect(() => {
    Server.call(packageName, 'generateAuthenticatorSecret', userAccount).then(
      (result) => {
        if (result) {
          setLoading(false);
        }
      },
    );
  }, [isLoading]);

  const handleOTP = (otpCode: string) => {
    verifyOTP(otpCode);
  };

  const verifyOTP = (otp: string) => {
    Server.call(packageName, 'verifyOTP', otp).then((result) => {
      if (result) {
        onComplete();
      } else {
        if (!result) {
          alert('Invalid OTP Code');
        }
      }
    });
  };

  const config = AuthenticatorConfig.getForAccount(userAccount);

  const copyToClipboard = () => {
    setCopyButton(!copyButton);
    navigator.clipboard.writeText(config?.secretKey);
  };

  const verifyScan = () => {
    AuthenticatorConfig.setEnabledForAccount(userAccount, true);
    onComplete();
  };
  return (
    <div className={style.AuthenticatorSetup}>
      <div className={style.BiometricContainer}>
        <div className={style.BiometricInfo}>
          <div className={style.BiometricInner}>
            <h1>How to setup two factor authentication</h1>
            <div className={style.BiometricUser}>
              <img src="/images/male_user.png" />
            </div>
          </div>
          <p>
            Open Google Authentication apps on your phone, if you not have it
            installed on your phone try search on the Playstore or Apple Store.
          </p>

          <div className={style.centerText}>
            {isLoading ? (
              loadingComponent || <span>...</span>
            ) : (
              <ImageView of={config?.secretKeyQR} width={300} height={300} />
            )}
            <p>
              Choose one of these following method: {'\n'}Scan using your Google
              Authenticator Apps or Copy below secret code to your 2 factor
              authenticator apps.
            </p>
            <p>If your using Copy Secret Method, hit Verify & Activate</p>
            <p>Secret Key:</p>
            <div className={style.copyContainer}>
              <h5>{config?.secretKey}</h5>
              <img
                onClick={copyToClipboard}
                src="/images/copy.png"
                className={style.copyIcon}
                alt="Copy Icon"
              />
            </div>
            <Button
              onClick={() => {
                !copyButton ? verifyScan() : setShowPopup(true);
              }}
            >
              {!copyButton ? 'Continue' : 'Verify & Activate'}
            </Button>
          </div>
        </div>
      </div>
      {/* Verify Popup */}
      {showPopup && (
        <div className={style.BiometricPopup}>
          <div className={style.BiometricPopupInner}>
            <img
              src="/images/close.png"
              className={style.closeButton}
              onClick={() => setShowPopup(false)}
            />
            <img src={'images/shieldIcon.png'} />
            <h1>Verify & Activate Two-Way Authentication</h1>
            <p>Enter the code generated by your 2-factor auth apps</p>
            <OTPInput onClick={handleOTP} />
          </div>
        </div>
      )}
    </div>
  );
};
