import {BefriendAction} from 'lincd-schema/lib/shapes/BefriendAction';
import {ShapeSet} from 'lincd/lib/collections/ShapeSet';
import React from 'react';
import './ContactList.scss';
import style from './ContactList.scss.json';
import {Person} from 'profile-plus/lib/shapes/Person';
import {Avatar} from 'profile-plus/lib/components/Avatar';
import {Organization} from 'lincd-schema/lib/shapes/Organization';
import {useNavigate} from 'react-router-dom';
import {ROUTES} from '../routes';
import {UserAccount} from 'lincd-sioc/lib/shapes/UserAccount';
import {ProfilePictureView} from 'profile-pics/lib/components/ProfilePictureView';

interface Contact {
  id: number;
  name: string;
  email: string;
  company: string;
  profession: string;
  picture: string;
}

interface ListProps {
  connections: ShapeSet<BefriendAction>;
}

const List: React.FC<ListProps> = ({connections}) => {
  const navigate = useNavigate();
  const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const alphabetRefs: Record<string, React.RefObject<HTMLLIElement>> = {};

  const scrollToContact = (letter: string) => {
    const contactRef = alphabetRefs[letter];
    if (contactRef?.current) {
      contactRef.current.scrollIntoView({behavior: 'smooth'});
    }
  };

  return (
    <div className={style.container}>
      <ul className={style.contactList}>
        {connections.map((connection) => {
          let contact = connection.object as Person;
          const firstLetter = contact.givenName?.charAt(0).toUpperCase();
          if (firstLetter && !alphabetRefs[firstLetter]) {
            alphabetRefs[firstLetter] = React.createRef();
          }
          let contactAccount = UserAccount.getAccountOf(contact as any);
          let org = Organization.getAffiliationsOf(contact).first();
          return (
            <li
              key={contact.uri}
              className={style.contactItem}
              ref={alphabetRefs[firstLetter]}
              onClick={() =>
                navigate(
                  ROUTES.profileDetails.path + '?uid=' + contactAccount.uri,
                )
              }
            >
              <div className={style.contactPicture}>
                {/*<img src={connection.profilePicture} alt={connection.name} />*/}
                {/*<Avatar of={contact} />*/}
                {contact.profilePicture ? (
                  <ProfilePictureView of={contact.profilePicture} />
                ) : (
                  <div>
                    <img
                      src="/images/el_icon.png"
                      alt={contact.givenName || ''}
                    />
                  </div>
                )}
              </div>
              <div className={style.contactDetails}>
                <h5 className={style.contactName}>
                  {contact.givenName} {contact.familyName}
                </h5>
                <p className={style.contactCompany}>{contact.alternateName}</p>
                {org && (
                  <>
                    <p className={style.contactCompany}>{org.name}</p>
                    <p className={style.contactCompany}>{org.email}</p>
                  </>
                )}
              </div>
            </li>
          );
        })}
      </ul>
      <div className={style.alphabetIndex}>
        {alphabet.split('').map((letter) => (
          <p key={letter} onClick={() => scrollToContact(letter)}>
            {letter}
          </p>
        ))}
      </div>
    </div>
  );
};

export default List;
