/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {Suspense, useEffect, useState} from 'react';
import {ErrorBoundary} from 'react-error-boundary';
import {Spinner} from './components/Spinner';
import {Error} from './components/Error';
import {AppRoot} from 'lincd-server-utils/lib/components/AppRoot';
import {Head} from 'lincd-server-utils/lib/components/Head';
import {Body} from 'lincd-server-utils/lib/components/Body';
import AppRoutes, {ROUTES} from './routes';
//Note that by default LINCD apps are set up with support for SCSS (sass) and CSS Modules
//So any .scss file needs to be imported by itself
import './App.scss';
import {CapacitorUpdater} from '@capgo/capacitor-updater';
import {Dialog} from '@capacitor/dialog';
import {SplashScreen} from '@capacitor/splash-screen';
//and then the .scss.json file needs to be imported to access the class names (this file will be automatically generated)
import style from './App.scss.json';
import {ProvideAuth} from 'lincd-auth/lib/hooks/useAuth';
import {Person} from 'profile-plus/lib/shapes/Person';
import {ClientAccount} from './shapes/ClientAccount';
import {CapacitorProvider} from 'lincd-capacitor/lib/hooks/useCapacitor';
import {useNavigate} from 'react-router-dom';
import {useAutomaticUpdates} from 'lincd-capacitor/lib/hooks/useAutomaticUpdates';
import {ProviderAccount} from './shapes/ProviderAccount';

export default function App() {
  const navigate = useNavigate();
  useAutomaticUpdates();

  return (
    <AppRoot>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, user-scalable=no, maximum-scale=1"
        />
        {/* <meta
          httpEquiv="Content-Security-Policy"
          content="default-src https://codepush.appcenter.ms 'self' data: gap: https://ssl.gstatic.com 'unsafe-eval'; style-src 'self' 'unsafe-inline'; media-src *"
        /> */}

        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link
          rel="preconnect"
          href="https://fonts.gstatic.com"
          crossOrigin=""
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap"
          rel="stylesheet"
        />
        <link
          href="https://cdn.jsdelivr.net/npm/react-date-picker@10.6.0/dist/DatePicker.min.css"
          rel="stylesheet"
        />
        <link
          href="https://cdn.jsdelivr.net/npm/react-calendar@4.8.0/dist/Calendar.min.css"
          rel="stylesheet"
        />
        {/*  Add tags to html <head> here, for example, a font <link href='https://fonts.someapi.com/...' />*/}
        <script src="https://www.eventbrite.com/static/widgets/eb_widgets.js"></script>
      </Head>
      <Body routes={ROUTES} pageStyles={style} className={style.App}>
        <ProvideAuth
          userType={Person}
          accountType={ClientAccount}
          availableAccountTypes={[ProviderAccount]}
        >
          <CapacitorProvider navigate={navigate}>
            <Suspense fallback={<Spinner />}>
              <ErrorBoundary FallbackComponent={Error}>
                <AppRoutes />
              </ErrorBoundary>
            </Suspense>
          </CapacitorProvider>
        </ProvideAuth>
      </Body>
    </AppRoot>
  );
}
