import React, {useEffect, useState} from 'react';
import {default as style} from './Admin.scss.json';
import './Admin.scss';
import {DefaultLayout} from '../layout/DefaultLayout';
import {ProviderAccount} from '../shapes/ProviderAccount';
import {Server} from 'lincd-server-utils/lib/utils/Server';
import {packageName} from '../package';
import {Button} from 'lincd-mui-base/lib/components/Button';
import {Person as SchemaPerson} from 'lincd-schema/lib/shapes/Person';
import {cl} from 'lincd/lib/utils/ClassNames';
import {Modal} from 'lincd-mui-base/lib/components/Modal';
import {PracticionerCard} from '../components/PracticionerCard';
import {TextField} from 'lincd-input/lib/components/TextField';

export default function Admin() {
  const [providers, setProviders] = useState([]);
  const [modalProvider, setModalProvider] = useState(false);
  const [selectedProvider, setSelectedProvider] = useState(null);
  const [searchTerm, setSearchTerm] = useState(''); // search input state
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // get unverified providers
    getProviders();
  }, []);

  const getProviders = async () => {
    try {
      setLoading(true);
      const result = await Server.call(packageName, 'getUnverifiedProviders');
      if (result.length > 0) {
        setProviders(result);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const onViewProvider = (provider: ProviderAccount, person: SchemaPerson) => {
    setSelectedProvider({provider, person});
    setModalProvider(true);
  };

  const onVerifyProvider = async (provider: ProviderAccount) => {
    provider.isVerified = true;

    const updatedProviders = providers.filter((p) => {
      // check if the provider has an id or email before comparing it
      if (p.userAccount.id && p.userAccount.id === provider.id) {
        return false;
      }
      if (p.userAccount.email && p.userAccount.email === provider.email) {
        return false;
      }
      return true;
    });

    // if equal, it means the provider is not in the list, so just call getProviders again
    if (updatedProviders.length === providers.length) {
      console.log('calling getProviders');
      await getProviders();
      return;
    }

    // Update the state
    setProviders(updatedProviders);
  };

  const onCloseModal = () => {
    setModalProvider(false);
    setSelectedProvider(null);
  };

  const scrollToTop = () => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  };

  // search filter by provider name
  const filteredProviders = providers.filter(
    ({userAccount: provider, user: person}) => {
      const name = provider.businessFirstName
        ? provider.businessFirstName + ' ' + provider.businessLastName
        : person.givenName + ' ' + person.familyName;
      return name.toLowerCase().includes(searchTerm.toLowerCase());
    },
  );

  return (
    <>
      <DefaultLayout
        isHome={false}
        colorHeader={'black'}
        title="Admin"
        showFooter={false}
        showBackButton={true}
        className={style.layout}
      >
        <div className={style.container}>
          <div className={style.headlineWrap}>
            <div>
              <h1 className={style.headline}>Provider List</h1>
              <p>{providers && providers.length} Unverified Providers</p>
            </div>
            <div>
              <form>
                <TextField
                  type="text"
                  placeholder="Search for providers"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className={style.searchInput}
                />
              </form>
            </div>
          </div>
          <div className={style.providersList}>
            <div className={cl(style.providerItem, style.header)}>
              <div className={style.providerName}>Provider Name</div>
              <div className={style.providerActions}>Actions</div>
            </div>
            {loading && <div className={style.loading}>Loading...</div>}
            {filteredProviders &&
              !loading &&
              filteredProviders.map(
                ({userAccount: provider, user: person}, i: number) => {
                  const name = provider.businessFirstName
                    ? provider.businessFirstName +
                      ' ' +
                      provider.businessLastName
                    : person.givenName + ' ' + person.familyName;

                  return (
                    <div key={i} className={style.providerItem}>
                      <div className={style.providerName}>{name}</div>
                      <div className={style.providerActions}>
                        <div className={style.btnGroup}>
                          <Button
                            className={style.btn}
                            onClick={() => onVerifyProvider(provider)}
                          >
                            Verify
                          </Button>
                          <Button
                            variant="outlined"
                            className={style.btn}
                            onClick={() => onViewProvider(provider, person)}
                          >
                            View
                          </Button>
                        </div>
                      </div>
                    </div>
                  );
                },
              )}
            <div className={style.scrollTop} onClick={scrollToTop}>
              Scroll to top
            </div>
          </div>
        </div>
      </DefaultLayout>
      <Modal
        isOpen={modalProvider}
        onClose={onCloseModal}
        renderContent={
          <div className={style.modalBody}>
            {selectedProvider && (
              <div className={style.modalContent}>
                <PracticionerCard
                  of={selectedProvider.person}
                  moreInformation={true}
                />
              </div>
            )}
          </div>
        }
      />
    </>
  );
}
