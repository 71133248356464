import React,{useEffect} from 'react';
import SearchBar from '../components/SearchBar';
import ContactList from '../components/ContactList';
import {DefaultLayout} from '../layout/DefaultLayout';
import './Connection.scss';
import style from './Connection.scss.json';
import {useAuth} from 'lincd-auth/lib/hooks/useAuth';
import {ClientAccount} from '../shapes/ClientAccount';
import {Person} from 'profile-plus/lib/shapes/Person';
import {BefriendAction} from 'lincd-schema/lib/shapes/BefriendAction';
import {ShapeSet} from 'lincd/lib/collections/ShapeSet';
import {Spinner} from '../components/Spinner';

const Connection = () => {
  let auth = useAuth<Person,ClientAccount>();
  const [connections,setConnections] =
    React.useState<ShapeSet<BefriendAction>>(null);
  const [sourceConnection,setSourceConnection] =
    React.useState<ShapeSet<BefriendAction>>(null);
  useEffect(() => {
    //bit hacky, but this is to be able to receive friendship shapes
    const x = BefriendAction;
    auth.userAccount.getConnections().then(({connections}) => {
      setConnections(connections);
      setSourceConnection(connections);
    });
  },[]);

  const handleInputChange = (query) => {
    if (query === '')
    {
      setConnections(sourceConnection); // Restore source data
    }
    else
    {
      query = query.toLowerCase();
      const searchResult = connections.filter((connection) => {
        let contact = connection.object as Person;
        const givenNameMatch = contact.givenName
          .toLowerCase()
          .includes(query.toLowerCase());
        const familyNameMatch = contact.familyName
          .toLowerCase()
          .includes(query.toLowerCase());

        console.log(`givenNameMatch: ${givenNameMatch}`);
        console.log(`familyNameMatch: ${familyNameMatch}`);

        // Return nodes that don't fully match the query for either givenName or familyName
        return givenNameMatch || familyNameMatch;
      });
      setConnections(searchResult);
    }
  };

  return (
    <DefaultLayout isHome={false} colorHeader={'black'} title="Connections">
      <div className={style.connectionContainer}>
        <h3>Search Connection</h3>
        <SearchBar
          placeholder="Search..."
          onSearch={() => ''}
          onInputChange={handleInputChange}
        />
        {!connections && <Spinner />}
        {connections && connections.size === 0 && (
          <p className={style.noContact}>
            No connections yet. Go ahead and connect with someone!
          </p>
        )}
        {connections && connections.size > 0 && (
          <ContactList connections={connections} />
        )}
      </div>
    </DefaultLayout>
  );
};

export default Connection;
