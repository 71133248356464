import React, {useEffect, useState} from 'react';
import './Events.scss';
import style from './Events.scss.json';
import {Event} from 'lincd-schema/lib/shapes/Event';
import {EventCard} from '../components/EventCard';
import {DefaultLayout} from '../layout/DefaultLayout';
import {Server} from 'lincd-server-utils/lib/utils/Server';
import {ClientAccount} from '../../src/shapes/ClientAccount';
import {packageName} from '../package';
import {Person} from 'profile-plus/lib/shapes/Person';
import {ShapeSet} from 'lincd/lib/collections/ShapeSet';
import {useAuth} from 'lincd-auth/lib/hooks/useAuth';
import {ClientEvent} from '../shapes/ClientEvent';
import {ProviderAccount} from '../shapes/ProviderAccount';
import ActionSheet from '../components/ActionSheet';
import {useLocation} from 'react-router-dom';
import {LinkedStorage} from 'lincd/lib/utils/LinkedStorage';
import {getShapeOrSubShape} from 'lincd/lib/utils/ShapeClass';
import {ProviderEvent} from '../shapes/ProviderEvent';

export default function Events() {
  const [events, setEvents] = useState<ShapeSet<Event>>(new ShapeSet<Event>());
  const auth = useAuth<Person, ClientAccount>();
  const [personName, setPersonName] = useState('');
  const [accountURI, setAccountURI] = useState<string>('');
  const location = useLocation();
  const user = auth.user;
  const account = auth.userAccount;
  let [accountProfile, setAccountProfile] =
    React.useState<ClientAccount | null>(null);

  function useQuery() {
    const {search} = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  let query = useQuery();
  const handleUsername = () => {
    let accountURI = query.get('uid');
    setAccountURI(accountURI);
    let queryAccount = ClientAccount.getFromURI(accountURI, false);
    LinkedStorage.loadShape(queryAccount).then(() => {
      //change it to either client account or provider account depending on the type of account in the returned data
      queryAccount = getShapeOrSubShape(queryAccount.namedNode, ClientAccount);
      //let's make sure to first load the data of the person
      let person = getShapeOrSubShape(queryAccount.accountOf.namedNode, Person);
      LinkedStorage.loadShape(person).then(() => {
        //and then update the state
        setAccountProfile(queryAccount);
      });
    });
  };

  useEffect(() => {
    if (location?.state) handleUsername();
    if (user) {
      let isProvider = auth.userAccount instanceof ProviderAccount;
      let getEventsProviderFn = isProvider
        ? 'getProviderEvents'
        : 'getClientEvents';
      Server.call(packageName, getEventsProviderFn).then(
        (clientEvents: ShapeSet<ClientEvent>) => {
          console.log('getClientEvents', clientEvents);
          if (clientEvents) {
            clientEvents = clientEvents.filter((ev) =>
              isProvider
                ? ev instanceof ProviderEvent
                : ev instanceof ClientEvent,
            );
            setEvents(clientEvents);
          } else {
            setEvents(new ShapeSet<Event>());
          }
        },
      );
    }
  }, [location?.state, user]);

  let person = getShapeOrSubShape(
    accountProfile?.accountOf?.namedNode,
    Person,
  ) as Person;

  //filter all events that are in the future
  let now = new Date();
  let futureEvents = events.filter((event) => {
    return now < event.startDate;
  });

  //filter all events that are happening now or ended maximum 1 day in the past
  //we do this because we are currently not saving time zone from the eventbrite api
  let nowEvents = events.filter((event) => {
    //start date is in the past but end date is in the future
    return event.startDate < now && now < event.endDate;
  });

  //filter all events whos ended is more than 1 day the past
  let pastEvents = events.filter((event) => {
    return event.endDate < now;
  });

  return (
    <DefaultLayout isHome={false} colorHeader={'black'} title="Events">
      <div className={style.header}>
        <div className={style.header__text}>
          <h2>
            Hello,{' '}
            {user?.givenName
              ? user?.givenName + ' ' + user?.familyName
              : account?.email}
          </h2>
          <p>Let&apos;s find your event!</p>
        </div>
        {/*<Avatar classNAme={style.header__userPhoto} of={auth.user} />*/}
      </div>
      <div className={style.container}>
        {nowEvents.size > 0 && (
          <>
            <h2>Happening Now</h2>
            <div className={style.eventListing}>
              {nowEvents.map((event) => {
                return <EventCard key={'key' + event.identifier} of={event} />;
              })}
            </div>
          </>
        )}
        {futureEvents.size > 0 && (
          <>
            <h2>Upcoming Events</h2>
            <div className={style.eventListing}>
              {futureEvents.map((event) => {
                return <EventCard key={'key' + event.identifier} of={event} />;
              })}
              {futureEvents.size === 0 && (
                <p>Stay tuned for upcoming events!</p>
              )}
            </div>
          </>
        )}
        {pastEvents.size > 0 && (
          <>
            <h2>Past Events</h2>
            <div className={style.eventListing}>
              {pastEvents.map((event) => {
                return <EventCard key={'key' + event.identifier} of={event} />;
              })}
            </div>
          </>
        )}
      </div>
      {person && (
        <ActionSheet
          imageSrc="/images/connect.jpg"
          uri={accountURI}
          heading={`You're connected with ${person.givenName} ${person.familyName}`}
          paragraph={`Congratulations! You are now connected`}
        />
      )}
    </DefaultLayout>
  );
}
