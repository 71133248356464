import {NamedNode} from 'lincd/lib/models';
import {linkedShape} from '../package';
import {Event} from 'lincd-schema/lib/shapes/Event';
import {emergentLives} from '../ontologies/emergent-lives';

@linkedShape
export class ProviderEvent extends Event
{
  static targetClass: NamedNode = emergentLives.ProviderEvent;
}
