import React,{useEffect,useState} from 'react';
import './SuccessfulRegistration.scss';
import style from './SuccessfulRegistration.scss.json';
import {DefaultLayout} from '../layout/DefaultLayout';
import {Icons} from '../components/Icons';
import {Button} from '../components/Button';
// import ConfettiExplosion from 'react-confetti-explosion';

const SuccessfulSignedUp = () => {
  const [isExploding,setIsExploding] = useState(false);

  const handleExplosion = () => {
    setIsExploding(true);
  };
  useEffect(() => {
    handleExplosion();
  },[]);

  return (
    <DefaultLayout isHome={false} colorHeader={'black'}>
      <div className={style.container}>
        {/*{isExploding && <ConfettiExplosion />}*/}
        <Icons.Success />
        <h2>Amazing!</h2>
        <p>You have signed up for the event!</p>
        <Button endIcon={<Icons.RightArrow />}>Continue</Button>
      </div>
    </DefaultLayout>
  );
};

export default SuccessfulSignedUp;
