import {Literal, NamedNode} from 'lincd/lib/models';
import {linkedShape} from '../package';
import {lincdAuthenticator} from '../ontologies/lincd-authenticator';
import {ImageObject} from 'lincd-schema/lib/shapes/ImageObject';
import {literalProperty, objectProperty} from 'lincd/lib/utils/ShapeDecorators';
import {Boolean} from 'lincd-xsd/lib/shapes/Boolean';
import {UserAccount} from 'lincd-sioc/lib/shapes/UserAccount';
import {Shape} from 'lincd/lib/shapes/Shape';

import {rdf} from 'lincd/lib/ontologies/rdf';
@linkedShape
export class AuthenticatorConfig extends Shape {
  static targetClass: NamedNode = lincdAuthenticator.AuthenticatorConfig;

  @objectProperty({
    path: lincdAuthenticator.secretKeyQR,
    shape: ImageObject,
    maxCount: 1,
  })
  get secretKeyQR(): ImageObject {
    return this.getOneAs(lincdAuthenticator.secretKeyQR, ImageObject);
  }
  set secretKeyQR(val: ImageObject) {
    this.overwrite(lincdAuthenticator.secretKeyQR, val.node);
  }

  @literalProperty({
    path: lincdAuthenticator.secretKey,
    maxCount: 1,
  })
  get secretKey(): string {
    return this.getValue(lincdAuthenticator.secretKey);
  }
  set secretKey(val: string) {
    this.overwrite(lincdAuthenticator.secretKey, new Literal(val));
  }

  @objectProperty({
    path: lincdAuthenticator.account,
    shape: UserAccount,
    maxCount: 1,
  })
  get account(): UserAccount {
    return this.getOneAs(lincdAuthenticator.account, UserAccount);
  }
  set account(val: UserAccount) {
    this.overwrite(lincdAuthenticator.account, val.node);
  }

  // @literalProperty({
  //   path: lincdAuthenticator.isTwoFactorEnabled,
  //   maxCount: 1,
  // })
  // get twoFactorEnabled(): boolean {
  //   return Boolean.isTrue(
  //     this.getOne(lincdAuthenticator.isTwoFactorEnabled) as Literal,
  //   );
  // }
  // set twoFactorEnabled(val: boolean) {
  //   this.overwrite(
  //     lincdAuthenticator.isTwoFactorEnabled,
  //     Boolean.toLiteral(val),
  //   );
  // }
  static getForAccount(account: UserAccount): AuthenticatorConfig {
    // return this.getLocalInstances().find((config) => {
    //   return config.account.namedNode === account.namedNode;
    // });
    let objectsPointingToAccount = account.getAllInverse(
      lincdAuthenticator.account,
    );
    let configNode = objectsPointingToAccount.find((obj) => {
      return obj.has(rdf.type, this.targetClass);
    });
    return configNode ? new AuthenticatorConfig(configNode) : null;
  }

  static setEnabledForAccount(account: UserAccount, enabled: boolean = true) {
    account.set(
      lincdAuthenticator.hasAuthenticatorEnabled,
      Boolean.toLiteral(enabled),
    );
  }
  static getEnabledForAccount(account: UserAccount) {
    return account.hasValue(lincdAuthenticator.hasAuthenticatorEnabled, 'true');
  }
  static setEnabledForAccountBiometric(
    account: UserAccount,
    enabled: boolean = true,
  ) {
    account.set(
      lincdAuthenticator.hasAuthenticatorEnabledBiometric,
      Boolean.toLiteral(enabled),
    );
  }
  static getEnabledForAccountBiometric(account: UserAccount) {
    return account.hasValue(
      lincdAuthenticator.hasAuthenticatorEnabledBiometric,
      'true',
    );
  }
}
