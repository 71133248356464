import React, {useEffect, useState} from 'react';
import './ProfileDetails.scss';
import style from './ProfileDetails.scss.json';
import {Button} from '../components/Button';
import {DefaultLayout} from '../layout/DefaultLayout';
import {useLocation} from 'react-router-dom';
import {LinkedStorage} from 'lincd/lib/utils/LinkedStorage';
import {ClientAccount} from '../shapes/ClientAccount';
import {getShapeOrSubShape} from 'lincd/lib/utils/ShapeClass';
import {Spinner} from '../components/Spinner';
import {Person} from 'profile-plus/lib/shapes/Person';
import {useAuth} from 'lincd-auth/lib/hooks/useAuth';
import {BefriendAction} from 'lincd-schema/lib/shapes/BefriendAction';
import {Organization} from 'lincd-schema/lib/shapes/Organization';
import {packageName} from '../package';
import {Server} from 'lincd-server-utils/lib/utils/Server';
import {ProfilePictureView} from 'profile-pics/lib/components/ProfilePictureView';
import {ProviderAccount} from '../shapes/ProviderAccount';

function useQuery() {
  const {search} = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const ProfileDetails = () => {
  // const navigate = useNavigate();
  const [isLoading, setLoading] = useState<boolean>(false);
  let auth = useAuth<Person, ClientAccount>();
  const [connection, setConnection] = useState<BefriendAction>(null);
  let query = useQuery();
  let [account, setAccount] = React.useState<
    ClientAccount | ProviderAccount | null
  >(null);

  useEffect(() => {
    setLoading(true);
    let accountURI = query.get('uid');
    let queryAccount = ClientAccount.getFromURI(accountURI, false);
    LinkedStorage.loadShape(queryAccount).then(() => {
      //change it to either client account or provider account depending on the type of account in the returned data
      queryAccount = getShapeOrSubShape(queryAccount.namedNode, ClientAccount);
      //let's make sure to first load the data of the person
      let person = getShapeOrSubShape(queryAccount.accountOf.namedNode, Person);

      let organization: Organization = Organization.getAffiliationsOf(
        person as any,
      ).first();

      Promise.all([
        LinkedStorage.loadShape(person),
        organization
          ? LinkedStorage.loadShape(organization)
          : Promise.resolve(),
      ]).then(() => {
        //and then update the state
        setAccount(queryAccount);
        setLoading(false);
      });
    });
  }, [query]);

  let person;
  if (account) {
    person = getShapeOrSubShape(account.accountOf.namedNode, Person) as Person;
  }

  useEffect(() => {
    //bit hacky, but this is to be able to receive friendship shapes
    const x = BefriendAction;
    if (account) {
      (auth.userAccount as ClientAccount)
        .getConnectionTo(person)
        .then((connection) => {
          setConnection(connection);
        });
    }
  }, [account]);

  if (!account) {
    return <Spinner />;
  }

  let organization: Organization =
    Organization.getAffiliationsOf(person).first();

  const handleConnection = () => {
    //if the user is not connecting with themselves
    if (account.namedNode !== auth.userAccount.namedNode) {
      Server.call(packageName, 'onSuccessfulQRScan', account.uri);
      setConnection(new BefriendAction());
    }
  };

  return (
    <>
      <DefaultLayout
        showBackButton={true}
        colorHeader={'black'}
        resetTopSafeArea={true}
      >
        {isLoading && <Spinner />}
        <div className={style.Container}>
          {person?.profilePicture ? (
            <ProfilePictureView
              of={person?.profilePicture}
              width={600}
              className={style.imageProfile}
            />
          ) : (
            <img src="/images/el_icon.png" alt="150" height="150" />
          )}
          <div className={style.ContainerInner}>
            {organization && (
              <div className={style.orgDetails}>
                {person.profilePicture2 && (
                  <ProfilePictureView
                    of={person.profilePicture2}
                    width={50}
                    className={style.businessLogo}
                  ></ProfilePictureView>
                )}
                {organization.legalName && (
                  <h2 className={style.orgName}>{organization.legalName}</h2>
                )}
              </div>
            )}
            <div className={style.profesiNameWrap}>
              <h2 className={style.ProfileName}>
                {(account as ProviderAccount)?.businessFirstName ||
                  person.givenName}{' '}
                {(account as ProviderAccount)?.businessLastName ||
                  person.familyName}
              </h2>
              {(account as ProviderAccount)?.isVerified && (
                <div className={style.verified}>
                  <p>Verified Provider</p>
                  <img
                    className={style.verifiedIcon}
                    src="/images/checkmark.png"
                    alt="verified"
                  />
                </div>
              )}
            </div>
            {organization?.alternateName && (
              <p className={style.profession}>{organization?.alternateName}</p>
            )}
            <div>
              <p className={style.contentParagraph}>{person.description}</p>
              {/*if this is not your own account, then show a connection button*/}
              {account.namedNode !== auth.userAccount.namedNode && (
                <div className={style.flexSide}>
                  {/*if you are connected to this person*/}
                  {connection ? (
                    <Button
                      startIcon={
                        <img
                          src="/images/add_contact_white.png"
                          width="38"
                          height="38"
                        />
                      }
                      className={style.connected}
                    >
                      Connected
                    </Button>
                  ) : (
                    <Button
                      startIcon={
                        <img
                          src="/images/addcontact.png"
                          width="38"
                          height="38"
                        />
                      }
                      onClick={handleConnection}
                      className={style.connect}
                    >
                      Connect
                    </Button>
                  )}

                  {/* <Button
                  startIcon={
                    <img src="/images/notes.png" width="38" height="38" />
                  }
                  className={style.notes}
                >
                  Notes
                </Button> */}
                </div>
              )}
              <div className={style.flexColumn}>
                {organization?.address && (
                  <div className={style.flexRow}>
                    <img src="/images/Location.png" className={style.imgIcon} />
                    <p>{organization?.address}</p>
                  </div>
                )}
                {organization?.email && (
                  <div className={style.flexRow}>
                    <img src="/images/Email.png" className={style.imgIcon} />
                    <p>
                      <a
                        href={'mailto:' + organization?.email}
                        target={'_blank'}
                        rel="noreferrer"
                      >
                        {organization?.email}
                      </a>
                    </p>
                  </div>
                )}
                {organization?.url && (
                  <div className={style.flexRow}>
                    <img src="/images/Website.png" className={style.imgIcon} />
                    <p>
                      <a
                        href={
                          organization?.url.includes('://')
                            ? organization?.url
                            : 'https://' + organization?.url
                        }
                        target={'_blank'}
                        rel="noreferrer"
                      >
                        Website
                      </a>
                    </p>
                  </div>
                )}
                {organization?.telephone && (
                  <div className={style.flexRow}>
                    <img
                      src="/images/profile/phone.png"
                      className={style.imgIcon}
                    />
                    <p>
                      <a
                        href={'tel: ' + organization?.telephone}
                        rel="noreferrer"
                      >
                        {organization?.telephone}
                      </a>
                    </p>
                  </div>
                )}

                {/*{organization?.telephone && (*/}
                {/*  <div className={style.flexRow}>*/}
                {/*    <Icons.Phone color="#222" />*/}
                {/*    <p className={style.practionerPhone}>*/}
                {/*      {organization?.telephone}*/}
                {/*    </p>*/}
                {/*  </div>*/}
                {/*)}*/}
              </div>
              {/* <div>
                <h3>Targeting</h3>
                <ul className={style.targetProfile}>
                  <li>Axiety</li>
                  <li>Chronic Pain</li>
                  <li>Compassion Fatigue</li>
                  <li>Emotional Trauma</li>
                  <li>Emotinal Wellbeing</li>
                </ul>
              </div> */}
            </div>
          </div>
        </div>
      </DefaultLayout>
    </>
  );
};

export default ProfileDetails;
