import React, {HTMLAttributes} from 'react';
import Container from '../components/Container';
import {Header} from './Header';
import './DefaultLayout.scss';
import style from './DefaultLayout.scss.json';
import {useNavigate} from 'react-router-dom';
import {useStyles} from 'lincd/lib/utils/Hooks';
import Footer from '../components/Footer';
import {SafeAreaContainer} from 'lincd-capacitor/lib/components/SafeAreaContainer';
import {cl} from 'lincd/lib/utils/ClassNames';
import {useCapacitor} from 'lincd-capacitor/lib/hooks/useCapacitor';


interface DefaultLayoutProps extends HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  isHome?: boolean;
  currentLink?: string;
  colorHeader: string;
  title?: string; // Make title optional by adding ?
  showHeader?: boolean;
  showFooter?: boolean;
  showBackButton?: boolean;
  // props handle safearea
  resetTopSafeArea?: boolean;
  resetBottomSafeArea?: boolean;
  colorSafeArea?: string;
}

export function DefaultLayout({
  children,
  isHome = false,
  colorHeader,
  title,
  showHeader = true,
  showFooter = true,
  showBackButton = false,

  // if resetTopSafeArea or resetBottomSafeArea is true, safearea will be disable
  resetTopSafeArea = false,
  resetBottomSafeArea = false,
  // set default background color for safearea is white
  colorSafeArea = '#fff',
  ...props
}: DefaultLayoutProps) {
  const navigate = useNavigate();

  props = useStyles(props, style.main);

  return (
    <main {...props}>
      <SafeAreaContainer
        resetTop={resetTopSafeArea}
        resetBottom={resetBottomSafeArea}
        color={colorSafeArea}
      >
        <Container>
          {showHeader && (
            <Header
              color={colorHeader}
              isHome={isHome}
              showBackButton={showBackButton}
              title={title}
              className={cl(resetTopSafeArea && style.headerSafeArea)} // extend more style when resetTopSafeArea is true
              onBack={() => navigate(-1)}
            />
          )}
          {children}
          {showFooter && (
            <>
              <Footer />
              <div className={style.moreSpaceForFooter} />
            </>
          )}
        </Container>
      </SafeAreaContainer>
    </main>
  );
}
