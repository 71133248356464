import React from 'react';
import './ContactSupport.scss';
import {default as style} from './ContactSupport.scss.json';
import {Link} from 'react-router-dom';
import {ROUTES} from '../routes';

export const TermsAndConditions = () => {
  return (
    <div className={style.info}>
      <p>
        By tapping &quot;Create account&quot; or &quot;Sign in&quot;, you agree
        to our <Link to={ROUTES.terms.path}>Terms</Link>. Learn how we process
        your data in our &nbsp;
        <Link to={ROUTES.privacy.path}>Privacy policy</Link>.
      </p>
      <p className={style.contact}>
        <svg width="48" height="48" fill="none">
          <path
            d="M42 24.44C42 13.46 33.48 6 24 6 14.62 6 6 13.3 6 24.56c-1.2.68-2 1.96-2 3.44v4c0 2.2 1.8 4 4 4 1.1 0 2-.9 2-2v-9.62c0-7.66 5.9-14.36 13.56-14.58C31.48 9.56 38 15.92 38 23.8V38H24c-1.1 0-2 .9-2 2s.9 2 2 2h14c2.2 0 4-1.8 4-4v-2.44c1.18-.62 2-1.84 2-3.28v-4.6c0-1.4-.82-2.62-2-3.24Z"
            fill="currentColor"
          />
          <path
            d="M18 28c1.1046 0 2-.8954 2-2s-.8954-2-2-2-2 .8954-2 2 .8954 2 2 2ZM30 28c1.1046 0 2-.8954 2-2s-.8954-2-2-2-2 .8954-2 2 .8954 2 2 2Z"
            fill="currentColor"
          />
          <path
            d="M36 22.06C35.04 16.36 30.08 12 24.1 12c-6.06 0-12.58 5.02-12.06 12.9 4.94-2.02 8.66-6.42 9.72-11.78 2.62 5.26 8 8.88 14.24 8.94Z"
            fill="currentColor"
          />
        </svg>
        <Link to={ROUTES.contact.path}>Contact support</Link>
      </p>
      <p>
        &copy; Emergent Lives & Emergent Wellness {new Date().getFullYear()}
      </p>
    </div>
  );
};
