import {Button} from '../components/Button';
import {Icons} from '../components/Icons';
import style from './AllAboutYouForm.scss.json';
import './AllAboutYouForm.scss';
import {InputField} from 'lincd-input/lib/components/InputField';
import {useAuth} from 'lincd-auth/lib/hooks/useAuth';
import {Person} from 'profile-plus/lib/shapes/Person';
import React, {useEffect, useState} from 'react';
import {ProviderAccount} from '../shapes/ProviderAccount';
import {Organization} from 'lincd-schema/lib/shapes/Organization';
import {ShapeValuesSet} from 'lincd/lib/collections/ShapeValuesSet';
import {Spinner} from '../components/Spinner';
import {LinkedStorage} from 'lincd/lib/utils/LinkedStorage';
import {cl} from 'lincd/lib/utils/ClassNames';

interface AllAboutProviderProps {
  onClick?;
  onSubmitLabel?: string;
}

const AllAboutProviderForm = ({
  onClick,
  onSubmitLabel,
}: AllAboutProviderProps) => {
  const auth = useAuth<Person, ProviderAccount>();
  const userAccount = auth.userAccount;
  const user = auth.user;

  const [organizationsLoaded, setOrganizationsLoaded] =
    useState<boolean>(false);

  const orgs: ShapeValuesSet<Organization> = Organization.getAffiliationsOf(
    auth.user,
  );

  let organization = orgs.first();
  useEffect(() => {
    if (!organization) {
      organization = new Organization();
      //make sure at least 1 organization is added to the shape values set
      orgs.add(organization);
      //(it will automatically be stored to the graph and connected to the user)
      organization.save();
    }
    LinkedStorage.loadShape(organization).then(() => {
      setOrganizationsLoaded(true);
    });
  }, []);

  if (!organizationsLoaded) return <Spinner />;

  return (
    <>
      <form className={style.container__form}>
        <div className={style.form__inputContainer}>
          <InputField
            type="text"
            name="company-name"
            placeholder="Company name"
            label="Company name"
            className={style.input}
            loadingImageClassName={style.loadingInput}
            of={organization}
            valueProperty="legalName"
            labelIcon={
              <img
                src="/images/profile/office.png"
                alt="user icon"
                height="20"
                width="20"
              />
            }
          />
          <InputField
            type="text"
            name="first-name"
            placeholder="First name"
            label="First name (for business purpose)"
            className={style.input}
            loadingImageClassName={style.loadingInput}
            of={userAccount}
            valueProperty="businessFirstName"
            labelIcon={
              <img
                src="/images/profile/office.png"
                alt="user icon"
                height="20"
                width="20"
              />
            }
          />
          <InputField
            type="text"
            name="last-name"
            placeholder="Last name"
            label="Last name (for business purpose)"
            className={style.input}
            loadingImageClassName={style.loadingInput}
            of={userAccount}
            valueProperty="businessLastName"
            labelIcon={
              <img
                src="/images/profile/office.png"
                alt="user icon"
                height="20"
                width="20"
              />
            }
          />
          <InputField
            type="email"
            name="email"
            placeholder="Company e-mail"
            label="Company e-mail"
            className={style.input}
            loadingImageClassName={style.loadingInput}
            of={organization}
            valueProperty="email"
            labelIcon={
              <img
                src="/images/profile/email.png"
                alt="user icon"
                height="20"
                width="20"
              />
            }
          />
          <InputField
            type="text"
            name="website"
            placeholder="Website"
            label="Website"
            className={style.input}
            loadingImageClassName={style.loadingInput}
            of={organization}
            valueProperty="url"
            labelIcon={
              <img
                src="/images/profile/website.png"
                alt="website icon"
                height="20"
                width="20"
              />
            }
          />
          <InputField
            type="tel"
            name="business-number"
            placeholder="Business phone"
            label="Business phone"
            className={style.input}
            loadingImageClassName={style.loadingInput}
            of={organization}
            valueProperty="telephone"
            labelIcon={
              <img
                src="/images/profile/phone.png"
                alt="whatsapp icon"
                height="20"
                width="20"
              />
            }
          />
          <InputField
            type="tel"
            name="fax"
            placeholder="Fax"
            label="Fax"
            className={style.input}
            loadingImageClassName={style.loadingInput}
            of={organization}
            valueProperty="faxNumber"
            labelIcon={
              <img
                src="/images/profile/fax.png"
                alt="fax icon"
                height="20"
                width="20"
              />
            }
          />
          <InputField
            type="text"
            name="bio"
            placeholder="Bio"
            label="Bio"
            className={cl(style.input, style.textarea)}
            loadingImageClassName={style.loadingInput}
            of={user}
            valueProperty="description"
            multiline={true}
          />
        </div>
      </form>
      <Button
        color="primary"
        endIcon={!onSubmitLabel && <Icons.RightArrow color="white" />}
        className={style.primaryButton}
        onClick={() => onClick()}
      >
        {onSubmitLabel || 'Continue'}
      </Button>
    </>
  );
};

export default AllAboutProviderForm;
