import React,{useCallback,useEffect,useState} from 'react';
import {BarcodeScanner} from '@capacitor-community/barcode-scanner';
import QRCodeScanner from '../components/QRCode';
import {Spinner} from '../components/Spinner';
import './Scanner.scss';
import {DefaultLayout} from '../layout/DefaultLayout';
import {useAuth} from 'lincd-auth/lib/hooks/useAuth';
import {Person} from 'profile-plus/lib/shapes/Person';
import {ClientAccount} from '../../src/shapes/ClientAccount';
import {ProviderAccount} from '../shapes/ProviderAccount';

const Scanner = () => {
  const [isLoading,setLoading] = useState<boolean>(true);
  const [scanResult,setScanResult] = useState<string | null>(null); // Store the scan result
  const [scanning,setScanning] = useState<boolean>(false);
  const auth = useAuth<Person,ClientAccount>();
  const providerAuth = useAuth<Person,ProviderAccount>();
  const user = auth.user;
  const account = auth.userAccount;
  const provider = providerAuth.userAccount;

  const startScan = useCallback(async () => {
    try
    {
      if (scanning)
      {
        return;
      }

      setLoading(false);
      setScanning(true);

      await BarcodeScanner.checkPermission({force: true});
      BarcodeScanner.hideBackground();

      const result = await BarcodeScanner.startScan();

      if (result.hasContent)
      {
        //validation for if user is scanning their own QR code
        if (result.content.includes(account.uri))
        {
          alert('You cannot scan your own QR code');
          return;
        }
        setScanResult(result.content);
      }
    } catch (error)
    {
      console.error('Error scanning barcode',error);
    } finally
    {
      setScanning(false);
    }
  },[scanning]);

  useEffect(() => {
    const checkPermissionAndStartScan = async () => {
      try
      {
        setLoading(true);
        await BarcodeScanner.checkPermission({force: true});
        BarcodeScanner.hideBackground();
        setLoading(false);

        const status = await BarcodeScanner.checkPermission({force: true});
        if (status.granted)
        {
          startScan();
        }
        // setTimeout(() => {
        //   setScanResult('https://app.emergentlives.com/data/account/apple-deni-ramdhan-icloud-com');
        // },2000);
      } catch (error)
      {
        console.error('Error checking permission or starting scan',error);
      }
    };

    checkPermissionAndStartScan();

    return () => {
      BarcodeScanner.showBackground();
      BarcodeScanner.stopScan();
    };
  },[]);

  // const stopScan = () => {
  //   BarcodeScanner.showBackground();
  //   BarcodeScanner.stopScan();
  // };

  if (isLoading) return <Spinner />;

  return (
    <DefaultLayout
      isHome={false}
      colorHeader={'white'}
      title="Scan"
      showHeader={true}
      showFooter={true}
    >
      <style>
        {`
          body > div:nth-child(2n + 3) {
            background-color: #fff !important;
          }
          
          #video {
            height:450px !important;
          }
        `}
      </style>

      <QRCodeScanner toggleCamera={startScan} scanResult={scanResult} />
    </DefaultLayout>
  );
};

export default Scanner;
