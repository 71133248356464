import React,{ChangeEvent,useState} from 'react';
import './SearchBar.scss';
import style from './SearchBar.scss.json';

interface SearchBarProps
{
  placeholder: string;
  onSearch: (query: string) => void;
  containerClassName?: string;
  onInputChange?: (query: string) => void; // New prop for input change
}

const SearchBar: React.FC<SearchBarProps> = ({
                                               placeholder,
                                               onSearch,
                                               containerClassName,
                                               onInputChange,
                                             }) => {
  const [searchQuery,setSearchQuery] = useState<string>('');

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newQuery = event.target.value;
    setSearchQuery(newQuery);
    if (onInputChange)
    {
      onInputChange(newQuery); // Trigger onInputChange prop
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter')
    {
      onSearch(searchQuery);
    }
  };

  return (
    <div className={`${style.SearchBarContainer} ${containerClassName || ''}`}>
      <div className={style.SearchBarInputContainer}>
        <input
          type="text"
          className={style.SearchBarInput}
          placeholder={placeholder}
          value={searchQuery}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
        />
      </div>
    </div>
  );
};

export default SearchBar;
