import React,{useEffect,useState} from 'react';
import PropTypes from 'prop-types';
import './ActionSheet.scss'; // Import your stylesheet for styling
import style from './ActionSheet.scss.json';
import {Button} from 'lincd-mui-base/lib/components/Button';
import {useNavigate} from 'react-router-dom';
import {ROUTES} from '../routes';

interface ActionProps
{
  imageSrc?: string;
  heading?: string;
  paragraph?: string;
  uri?: string;
}

const ActionSheet = ({imageSrc,heading,paragraph,uri}: ActionProps) => {
  const navigate = useNavigate();
  const [hide,setHide] = useState<boolean>(false);

  const handleClearState = () => {
    // Create a new location object without the state
    const locationWithoutState = {...location,state: undefined};

    // Replace the current location with the new one
    navigate(locationWithoutState);
  };
  const handleNavigate = () => {
    setHide(true);
    handleClearState();
    navigate(ROUTES.profileDetails.path + '?uid=' + uri,{
      state: {connected: true},
    });
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      document
        .querySelector(`.${style.actionSheet}`)
        ?.classList.add(style.active);
    },200); // Adjust the delay time as needed

    return () => {
      clearTimeout(timeoutId);
    };
  },[]);
  return (
    <>
      {!hide && (
        <div className={style.containerBackground}>
          <div className={style.actionSheet}>
            <div className={style.imageContainer}>
              <img
                src={imageSrc}
                alt="Action Sheet Image"
                className={style.image}
              />
            </div>
            <h2 className={style.heading}>{heading}</h2>
            <p className={style.paragraph}>{paragraph}</p>
            <Button
              onClick={handleNavigate}
              className={style.buttonActionSheet}
            >
              View their profile
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

ActionSheet.propTypes = {
  imageSrc: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  paragraph: PropTypes.string.isRequired,
};

export default ActionSheet;
