import React,{useRef} from 'react';
import styles from './IconButton.scss.json';
import './IconButton.scss';

interface IconButtonProps
{
  onClick?: () => void;
  iconImg: string;
  title: string;
  active?: boolean;
  toggle?: boolean;
  imageClassName?: string;
}

interface IconButtonMethods
{
  handleClick: () => void;
}

const IconButton: React.ForwardRefRenderFunction<
  IconButtonMethods,
  IconButtonProps
> = (props,ref) => {
  const buttonRef = useRef<HTMLDivElement>(null);

  const methods: IconButtonMethods = {
    handleClick: () => {
      if (props.onClick)
      {
        props.onClick();
      }
    },
  };

  // Expose the methods to the parent component
  if (ref)
  {
    if (typeof ref === 'function')
    {
      ref(methods);
    }
    else if (typeof ref === 'object')
    {
      ref.current = methods;
    }
  }

  const containerClassName = `${styles.buttonContainer} ${
    props.active ? styles.active : ''
  }`;

  return (
    <div
      ref={buttonRef}
      className={containerClassName}
      onClick={methods.handleClick}
    >
      <div className={styles.buttonInner}>
        <img src={props.iconImg} className={styles.IconImg} alt={props.title} />
        <p className={styles.IconTitle}>{props.title}</p>
      </div>
    </div>
  );
};

export default React.forwardRef(IconButton);
