import './Spinner.scss';
import * as style from './Spinner.scss.json';

export function Spinner()
{
  return (
    <div className={style.spinner}>
      <div className={style.innerSpin}>
        <img
          className={style.imgSpinner}
          src="/images/spinners.gif"
          alt="spinner-emergent-lives"
        />
        {/* <p className={style.fadeIn}>
        Your information is on its way. Thank you for your patience!
      </p> */}
      </div>
    </div>
  );
}
