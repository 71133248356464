import React, {lazy, Suspense} from 'react';
import {Route, Routes, useNavigate} from 'react-router-dom';
import {Spinner} from './components/Spinner';
import {RequireAuth} from 'lincd-auth/lib/components/RequireAuth';

//In React 18 you can use 'lazy' to import pages only when you need them.
//This will cause webpack to create multiple bundles, and the right bundles are automatically loaded

interface Route {
  path: string;
  component: React.LazyExoticComponent<() => JSX.Element>;
  render?: () => JSX.Element;
}

export const ROUTES = {
  welcome: {
    path: '/',
    name: 'Welcome',
    component: lazy(() => import('./pages/Welcome')),
  },
  index: {
    path: '/event/:id',
    name: 'Home',
    component: lazy(() => import('./pages/Home' /* webpackPrefetch: true */)),
    requireAuth: true,
  },
  profile: {
    path: '/profile',
    name: 'Profile',
    component: lazy(
      () => import('./pages/Profile' /* webpackPrefetch: true */),
    ),
    requireAuth: true,
  },
  privacy: {
    path: '/privacy',
    name: 'Privacy',
    component: lazy(
      () => import('./pages/PrivacyPolicy' /* webpackPrefetch: true */),
    ),
  },
  terms: {
    path: '/terms',
    name: 'Terms of Use',
    component: lazy(
      () => import('./pages/TermsOfUse' /* webpackPrefetch: true */),
    ),
  },
  // requireAuth: true,
  allAboutYou: {
    path: '/all-about-you',
    name: 'All About You',
    component: lazy(
      () => import('./pages/AllAboutYou' /* webpackPrefetch: true */),
    ),
    requireAuth: true,
  },
  allAboutProvider: {
    path: '/all-about-provider',
    name: 'All About Provider',
    component: lazy(
      () => import('./pages/AllAboutProvider' /* webpackPrefetch: true */),
    ),
    requireAuth: true,
  },
  events: {
    path: '/events',
    name: 'Event List',
    component: lazy(() => import('./pages/Events' /* webpackPrefetch: true */)),
    requireAuth: true,
  },
  // event_view: {
  //   path: '/events/:id',
  //   name: 'Event View',
  //   component: lazy(
  //     () => import('./pages/EventView' /* webpackPrefetch: true */),
  //   ),
  // },
  successfulRegistration: {
    path: '/successful-registration',
    name: 'Succesfull Registration',
    component: lazy(
      () =>
        import('./pages/SuccessfulRegistration' /* webpackPrefetch: true */),
    ),
    requireAuth: true,
  },
  scanner: {
    path: '/scanner',
    name: 'QR Code Scanner',
    component: lazy(
      () => import('./pages/Scanner' /* webpackPrefetch: true */),
    ),
    requireAuth: true,
  },
  connections: {
    path: '/connections',
    name: 'Connections',
    component: lazy(() => import('./pages/Connection')),
    requireAuth: true,
  },
  quests: {
    path: '/quests',
    name: 'Quests',
    component: lazy(() => import('./pages/Quests')),
    requireAuth: true,
  },
  profileDetails: {
    path: '/profile-details',
    name: 'Profile Details',
    component: lazy(
      () => import('./pages/ProfileDetails' /* webpackPrefetch: true */),
    ),
    requireAuth: true,
  },
  biometric: {
    path: '/two-way-authentication',
    name: 'Biometric Authentication',
    component: lazy(
      () => import('./pages/BiometricAuth' /* webpackPrefetch: true */),
    ),
    // requireAuth: true,
  },
  setupGoogleAuthenticator: {
    path: '/setup-google-authenticator',
    name: 'Setup Google Authenticator',
    component: lazy(
      () => import('./pages/GoogleAuthenticator' /* webpackPrefetch: true */),
    ),
    // requireAuth: true,
  },
  signin: {
    path: '/signin',
    name: 'SignIn',
    component: lazy(() => import('./pages/Signin' /* webpackPrefetch: true */)),
    // requireAuth: true,
  },
  contact: {
    path: '/contact',
    name: 'Contact',
    component: lazy(() => import('./pages/Contact')),
  },
  reset_password_callback: {
    path: '/auth/reset-password',
    component: lazy(
      () => import('lincd-auth/lib/components/ForgotPasswordCallback'),
    ),
  },
  admin: {
    path: '/admin',
    requireAuth: true,
    component: lazy(() => import('./pages/Admin')),
  },
};

export default function AppRoutes() {
  const navigate = useNavigate();

  const onPasswordIsReset = () => {
    navigate(ROUTES.welcome.path);
    // TODO: implement snackbar message
  };

  return (
    <Routes>
      {Object.keys(ROUTES).map((routeName) => {
        let route = ROUTES[routeName];
        let Wrapper = route.requireAuth ? RequireAuth : React.Fragment;
        let Component = route.component;
        return (
          <Route
            key={route.path}
            path={route.path}
            element={
              <Wrapper>
                <Suspense fallback={<Spinner />}>
                  <Component
                    {...(routeName === 'reset_password_callback' && {
                      onPasswordIsReset: onPasswordIsReset,
                    })}
                  />
                </Suspense>
              </Wrapper>
            }
          />
        );
      })}
    </Routes>
  );
}
