import {linkedPackage} from 'lincd/lib/utils/Module';

export const {
  linkedComponent,
  linkedSetComponent,
  linkedComponentClass,
  linkedShape,
  linkedUtil,
  linkedOntology,
  registerPackageExport,
  registerPackageModule,
  packageExports,
  packageName,
} = linkedPackage('emergent-lives');
