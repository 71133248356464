"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.NodeFactory = void 0;
/*
 * This Source Code Form is subject to the terms of the Mozilla Public
 * License, v. 2.0. If a copy of the MPL was not distributed with this
 * file, You can obtain one at https://mozilla.org/MPL/2.0/.
 */
const models_1 = require("lincd/lib/models");
const package_1 = require("../package");
let NodeFactory = class NodeFactory {
    static getOrCreateFromAny(resource) {
        if (typeof resource === 'string') {
            if (this.isURI(resource)) {
                return models_1.NamedNode.getOrCreate(resource);
            }
            else {
                return new models_1.Literal(resource);
            }
        }
        else if (typeof resource === 'number') {
            return new models_1.Literal(resource.toString());
        }
        else if (resource instanceof Map) {
            return this.createFromMap(resource);
        }
        else if (typeof resource === 'boolean') {
            //can't use xsd.boolean from xsd ontology here because of circular references
            var boolean = models_1.NamedNode.getOrCreate('http://www.w3.org/2001/XMLSchema#boolean');
            return new models_1.Literal(resource.toString(), boolean);
        }
    }
    static getFromToString(string) {
        if (models_1.Literal.isLiteralString(string)) {
            return new models_1.Literal(string);
        }
        else {
            return models_1.NamedNode.getNamedNode(string);
        }
    }
    static getOrCreateNamedNodeFromAny(resource) {
        if (typeof resource === 'string' && this.isURI(resource)) {
            return models_1.NamedNode.getOrCreate(resource);
        }
        else if (resource instanceof Map) {
            return this.createFromMap(resource);
        }
        else if (resource instanceof models_1.NamedNode) {
            return resource;
        }
    }
    static createFromMap(propertyMap) {
        var res = models_1.NamedNode.create();
        for (let [key, value] of propertyMap) {
            res.set(this.getOrCreateNamedNodeFromAny(key), this.getOrCreateFromAny(value));
        }
        return res;
    }
    static graphFromSPARQL(uriString, bnodes) {
        var firstChar = uriString.substr(0, 1);
        if (firstChar == '<') {
            return models_1.Graph.getOrCreate(uriString.substr(1, uriString.length - 2));
        }
        else if (firstChar == '_') {
            //hmmmm a blanknode as a graph, we can't really do both at the moment
            //maybe a BlanknodeGraph Object? implementing IBlankNode?
            throw new Error('blanknodes as graphs are not implemented yet');
            //return new Graph(uriString);
        }
    }
    static uriResourceFromSPARQL(uriString, bnodes) {
        var firstChar = uriString.substr(0, 1);
        if (firstChar == '<') {
            return this.createNamedNodeFromSparql(uriString, bnodes);
        }
        else if (firstChar == '_') {
            return bnodes.getNamedNodeOrCreateBlankNode(uriString);
            //return new BlankNode(uriString);
        }
    }
    static literalResourceFromSPARQL(literalString) {
        return models_1.Literal.fromString(literalString);
    }
    static resourceFromSPARQL(resourceString, bnodes) {
        var firstChar = resourceString.substr(0, 1);
        if (firstChar == '"') {
            return this.literalResourceFromSPARQL(resourceString);
        }
        else if (firstChar == '_') {
            return bnodes.getNamedNodeOrCreateBlankNode(resourceString);
        }
        else if (firstChar == '<') {
            return this.createNamedNodeFromSparql(resourceString, bnodes);
        }
        throw new Error('Invalid format, this is not a node');
    }
    /**
     * returns true if string is a valid URI
     * requires a http:// https:// ftp:// or other prefix with :// to be part of the string (so www.test.com is not valid)
     * @param string
     */
    static isURI(string) {
        //combining https://www.regextester.com/94092 and https://stackoverflow.com/a/163684/977206
        //this might be good too if we need something stricter / better: https://raw.githubusercontent.com/jhermsmeier/uri.regex/master/pattern.js
        return /\w+:\/\/[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]/.test(string);
    }
    static createNamedNodeFromSparql(uriString, bnodes) {
        //TODO: refactor this class, rename bnodes to node map and simplify using a more general bnodes.getResource(potentialTmpOrBlanknodeUri)
        return bnodes.getOrCreateNamedNode(uriString.substr(1, uriString.length - 2));
        // return NamedNode.getOrCreate(uriString.substr(1,uriString.length-2));
    }
};
exports.NodeFactory = NodeFactory;
exports.NodeFactory = NodeFactory = __decorate([
    package_1.linkedUtil
], NodeFactory);
