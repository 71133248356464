import React from 'react';
import './PracticionerCard.scss';
import {default as style} from './PracticionerCard.scss.json';
import {linkedComponent, registerPackageModule} from '../package';
import {Person} from 'profile-plus/lib/shapes/Person';
import {Icons} from './Icons';
import {Organization} from 'lincd-schema/lib/shapes/Organization';
import {UserAccount} from 'lincd-sioc/lib/shapes/UserAccount';
import {useNavigate} from 'react-router-dom';
import {ROUTES} from '../routes';
import {ProfilePictureView} from 'profile-pics/lib/components/ProfilePictureView';
import {ProviderAccount} from '../shapes/ProviderAccount';

interface PracticionerCardProps {
  moreInformation?: boolean;
}
export const PracticionerCard = linkedComponent<Person, PracticionerCardProps>(
  Person,
  ({source, moreInformation = false}) => {
    let person = source;
    let practitionerAccount: ProviderAccount = UserAccount.getAccountOf(
      person as any,
    ) as ProviderAccount;
    let organization: Organization =
      Organization.getAffiliationsOf(person).first();

    const navigation = useNavigate();

    return (
      <>
        <div
          className={style.PracticionerPerson}
          onClick={() =>
            navigation(
              ROUTES.profileDetails.path + '?uid=' + practitionerAccount.uri,
            )
          }
        >
          <div className={style.imageContainer}>
            {person?.profilePicture ? (
              <ProfilePictureView
                of={person.profilePicture}
                className={style.imgDoctor}
              />
            ) : (
              <img src="/images/el_icon.png" alt="50" height="50" />
            )}
            {/*{organization?.image ? (*/}
            {/*  <ImageView of={organization?.image} className={style.imgDoctor} />*/}
            {/*) : (*/}
            {/*  <img src="/images/el_icon.png" alt="50" height="50" />*/}
            {/*)}*/}
          </div>
          <div className={style.PracticionerContentList}>
            <div className={style.PracticionerInnerContentList}>
              <div className={style.profesiNameWrap}>
                {practitionerAccount?.isVerified && (
                  <img
                    className={style.verifiedIcon}
                    src="/images/checkmark.png"
                    alt="verified"
                  />
                )}
                <h5 className={style.profesiName}>
                  {((practitionerAccount as ProviderAccount)
                    ?.businessFirstName || person?.givenName) +
                    ' ' +
                    ((practitionerAccount as ProviderAccount)
                      ?.businessLastName || person?.familyName)}
                </h5>
              </div>
              {/* <p className={style.profesi}>{person.description}</p> */}
              {person?.alternateName && (
                <p className={style.profesiDesc}>{person?.alternateName}</p>
              )}
              {organization?.address || organization?.telephone ? (
                <div className={style.practicionerContact}>
                  {organization?.address && (
                    <div className={style.flexRow}>
                      <Icons.Location color="#222" />
                      <p className={style.practionerLocation}>
                        {organization?.address}
                      </p>
                    </div>
                  )}
                </div>
              ) : null}
              {moreInformation && person.description && (
                <p className={style.truncate}>{person.description}</p>
              )}
            </div>
          </div>
        </div>
      </>
    );
  },
);

//register all components in this file
registerPackageModule(module);
