/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import './mainEvent.scss';
import {default as style} from './mainEvent.scss.json';
import {linkedComponent,registerPackageModule} from '../package';
import {Event} from 'lincd-schema/lib/shapes/Event';
import {convertToLocalDate,parseDescription} from '../helper/convertTime';

//TODO: replace SHAPE with an actual Shape class
export const MainEvent = linkedComponent<Event>(
  Event.request((event) => [
    event.name,
    event.identifier,
    event.image?.contentUrl,
    event.startDate as any,
    event.description,
  ]),
  ({linkedData}) => {
    const [name,id,image,start,summary] = linkedData;
    // const [currentBannerIndex, setCurrentBannerIndex] = useState(0);

    // const handleNextBanner = () => {
    //   setCurrentBannerIndex(
    //     (prevIndex) => (prevIndex + 1) % event.length,
    //   );
    // };

    // useEffect(() => {
    //   const interval = setInterval(handleNextBanner, 3000); // Slide every 3 seconds
    //   return () => {
    //     clearInterval(interval);
    //   };
    // }, []);

    // const currentBanner = sourceEvent[currentBannerIndex];
    return (
      <div className={style.MainEventContainer}>
        <div key={'event' + id} className={style.carouselItem}>
          <img src={image} className={style.carouselImg} />
          <h1>{name}</h1>
         <p>{convertToLocalDate(start)}</p>
        <a target='_blank' href={`https://www.eventbrite.com/e/${id}/`} rel="noreferrer">More info &raquo;</a>
         {/*<div className={parseDescription(style.description)} dangerouslySetInnerHTML={{__html:summary}} />*/}

        </div>
      </div>
    );
  },
);

//register all components in this file
registerPackageModule(module);
