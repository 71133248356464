import './BiometricAuth.scss';
import './GoogleAuthenticator.scss';
import {DefaultLayout} from '../layout/DefaultLayout';
import {ROUTES} from '../routes';
import {useNavigate} from 'react-router-dom';
import {Spinner} from '../components/Spinner';
import {AuthenticatorSetup} from 'lincd-authenticator/src/components/AuthenticatorSetup';

const GoogleAuthenticator = () => {
  let navigate = useNavigate();
  return (
    <DefaultLayout isHome={false} colorHeader="black" showBackButton={true}>
      <AuthenticatorSetup
        loadingComponent={<Spinner />}
        onComplete={() => {
          navigate(ROUTES.biometric.path);
        }}
      />
    </DefaultLayout>
  );
};

export default GoogleAuthenticator;
