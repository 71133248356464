import {NamedNode} from 'lincd/lib/models';
import {JSONLD} from 'lincd-jsonld/lib/utils/JSONLD';
import {createNameSpace} from 'lincd/lib/utils/NameSpace';
import {linkedOntology} from '../package';
//import all the exports of this file as one variable called _this (we need this at the end)
import * as _this from './emergent-lives';

/**
 * Load the data of this ontology into memory, thus adding the properties of the entities of this ontology to the local graph.
 */
export const loadData = () => {
  return import('../data/emergent-lives.json').then((data) =>
    JSONLD.parse(data),
  );
};

/**
 * The namespace of this ontology, which can be used to create NamedNodes with URI's not listed in this file
 */
export const ns = createNameSpace('http://lincd.org/ont/emergent-lives/');

/**
 * The NamedNode of the ontology itself
 */
export const _self: NamedNode = ns('');

//A list of all the entities (Classes & Properties) of this ontology, each exported as a NamedNode
//classes
export const ClientAccount: NamedNode = ns('ClientAccount');
export const ProviderAccount: NamedNode = ns('ProviderAccount');
export const ClientEvent: NamedNode = ns('ClientEvent');
export const ProviderEvent: NamedNode = ns('ProviderEvent');
//properties
export const setupComplete: NamedNode = ns('setupComplete');
export const qrCode: NamedNode = ns('qrCode');
export const providerEvent: NamedNode = ns('providerEvent');
export const legalName: NamedNode = ns('legalName');
export const telephone: NamedNode = ns('telephone');
export const eventbriteEmail: NamedNode = ns('eventbriteEmail');
export const code: NamedNode = ns('code');
export const businessFirstName: NamedNode = ns('businessFirstName');
export const businessLastName: NamedNode = ns('businessLastName');
export const faxNumber: NamedNode = ns('faxNumber');
export const isVerified: NamedNode = ns('isVerified');

//An extra grouping object so all the entities can be accessed from the prefix/name
export const emergentLives = {
  ClientAccount,
  ProviderAccount,
  setupComplete,
  qrCode,
  ClientEvent,
  ProviderEvent,
  providerEvent,
  legalName,
  telephone,
  eventbriteEmail,
  code,
  businessFirstName,
  businessLastName,
  faxNumber,
  isVerified,
};

//Registers this ontology to LINCD.JS, so that data loading can be automated amongst other things
linkedOntology(
  _this,
  ns,
  'emergent-lives',
  loadData,
  '../data/emergent-lives.json',
);
