import React,{ChangeEvent,forwardRef,InputHTMLAttributes,Ref,useState} from 'react';
import './Checkbox.scss';
import {default as style} from './Checkbox.scss.json';
import {cl} from 'lincd/lib/utils/ClassNames';

interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement>
{
  title: string;
}

export const Checkbox = forwardRef<HTMLInputElement,CheckboxProps>(
  (props,ref: Ref<HTMLInputElement>) => {
    const {title,...inputProps} = props;

    const defaultChecked = inputProps.checked ? inputProps.checked : false;
    const [isChecked,setIsChecked] = useState(defaultChecked);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
      if (inputProps.onChange)
      {
        inputProps.onChange(e);
      }

      setIsChecked((prev) => !prev);
    };

    return (
      <div className={style.root}>
        <label className={style.label}>
          <input
            ref={ref}
            type="checkbox"
            className={cl(style.input)}
            checked={isChecked}
            onChange={handleChange}
            {...inputProps}
          />
          {title && <span>{title}</span>}
        </label>
      </div>
    );
  },
);

Checkbox.displayName = 'Checkbox';

// interface CheckboxProps {
//   title: string;
//   checked?: boolean;
//   onChange?: (e) => void;
// }

// export const Checkbox = forwardRef<
//   InputHTMLAttributes<HTMLInputElement>,
//   CheckboxProps
// >((props, ref) => {
//   const {title, checked, onChange, ...inputProps} = props;

//   const defaultChecked = checked ? checked : false;
//   const [isChecked, setIsChecked] = useState(defaultChecked);

//   const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
//     if (onChange) {
//       onChange(e);
//     }

//     setIsChecked((prev) => !prev);
//   };

//   return (
//     <div className={style.root}>
//       <label className={style.label}>
//         <input
//           ref={ref}
//           type="checkbox"
//           className={cl(style.input)}
//           checked={isChecked}
//           onChange={handleChange}
//           {...inputProps}
//         />
//         {title && <span>{title}</span>}
//       </label>
//     </div>
//   );
// });

// Checkbox.displayName = 'Checkbox';
