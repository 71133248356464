import * as React from 'react';
// import {Select} from '@mui/base/Select';
// import {Option} from '@mui/base/Option';
import style from './SelectDropdown.scss.json';
import './SelectDropdown.scss';
import {cl} from 'lincd/lib/utils/ClassNames';

interface SelectDropdownProps {
  name?: string;
  label?: string;
  labelIcon: any;
  placeholder?: string;
  className?;
  options: Array<string>;
  startAdornment?;
  of?;
  defaultValue?: string;
  property: string;
}

function renderValues(option) {
  if (option == null) {
    return <span className={style.placeholder}>-- Select Gender --</span>;
  }

  return <span>{option.label}</span>;
}

export function SelectDropdown({
  name,
  label,
  labelIcon,
  placeholder,
  options,
  className,
  startAdornment,
  of,
  property,
}: SelectDropdownProps) {
  // const handleChange = (e, newValue) => {
  const handleChange = (e) => {
    let newValue = e.currentTarget.value;
    if (newValue === '--Select--') {
      of[property] = null;
    } else {
      of[property] = newValue;
    }
  };

  return (
    <div className={style.container}>
      <label className={style.labelContainer}>
        {labelIcon} {label}
      </label>
      {startAdornment && (
        <img
          src={startAdornment.props.src}
          alt="gender icon"
          height="26"
          width="26"
        />
      )}
      <div className={style.selectField}>
        <select
          className={cl(style.dropdown__button, className)}
          // slotProps={{
          //   listbox: {className: style.listbox},
          //   popper: {className: style.popper},
          // }}
          name={name}
          // placeholder={placeholder}
          onChange={handleChange}
          defaultValue={of && property ? of[property] : '--Select--'}
          // renderValue={renderValues}
        >
          <option
            // className={cl(style.dropdown__option, className)}
            value={'--Select--'}
          >
            --Select--
          </option>
          {options.map((el) => {
            return (
              <option
                // className={cl(style.dropdown__option, className)}
                value={el}
                key={el}
              >
                {el}
              </option>
            );
          })}
        </select>
      </div>
    </div>
  );
}
