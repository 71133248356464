import React from 'react';
import './Contact.scss';
import {default as style} from './Contact.scss.json';
import {DefaultLayout} from '../layout/DefaultLayout';

export default function Contact() {
  return (
    <DefaultLayout
      isHome={false}
      colorHeader={'black'}
      title="Contact"
      showBackButton={true}
      showFooter={false}
      className={style.layout}
    >
      <div className={style.wrapper}>
        <iframe
          className={style.iframe}
          src="https://embed.wotnot.io/3tVuNhdJiJ3Z095424403448vwwI7QgX/bot/53ox6kwj8Xk3102837474621vqy4saKw?display_header=false&history_retention=false"
          frameBorder="0"
        ></iframe>
      </div>
    </DefaultLayout>
  );
}
