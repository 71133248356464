import React from 'react';
import style from './AllAboutYou.scss.json';
import './AllAboutYou.scss';
import {useAuth} from 'lincd-auth/lib/hooks/useAuth';
import {Person} from 'profile-plus/lib/shapes/Person';
import {useNavigate} from 'react-router-dom';
import {ProviderAccount} from '../shapes/ProviderAccount';
import {DefaultLayout} from '../layout/DefaultLayout';
import AllAboutProviderForm from '../components/AllAboutProviderForm';
import {ROUTES} from '../routes';
import {ProfilePictureUploader} from 'profile-plus/lib/components/ProfilePictureUploader';

export default function AllAboutProvider() {
  const auth = useAuth<Person, ProviderAccount>();
  const user = auth.user;
  const navigate = useNavigate();

  const onSubmit = () => {
    user.profileSetupCompleted = true;
    navigate(ROUTES.events.path);
  };

  return (
    <DefaultLayout
      isHome={false}
      colorHeader={'black'}
      showHeader={true}
      showFooter={false}
      // className={style.layout}
    >
      <div className={style.wrapper}>
        <h1 className={style.headline}>All About Your Offering</h1>
        <p className={style.subtitle}>
          Please tell us more about your offering, so we can find the best
          connections for you.
        </p>
        <p>Company logo:</p>
        <ProfilePictureUploader
          property="profilePicture2"
          thumbnailHeight={200}
          aspectRatio={1}
        />
        <AllAboutProviderForm onClick={onSubmit} onSubmitLabel={`Continue`} />
      </div>
    </DefaultLayout>
  );
}
