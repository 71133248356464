import React from 'react';
import './Practicioners.scss';
import {default as style} from './Practicioners.scss.json';
import {linkedComponent,registerPackageModule} from '../package';
import {useNavigate} from 'react-router-dom';
import {Event} from 'lincd-schema/lib/shapes/Event';
import {Icons} from '../components/Icons';

//TODO: replace SHAPE with an actual Shape class
export const Practicioner = linkedComponent<Event>(
  Event.request((event) => [event.attendees]),
  ({linkedData}) => {
    const [person] = linkedData;
    const navigate = useNavigate();
    const handleNavigate = (uid: string) => {
      navigate(`/profile/${uid}`);
    };

    return (
      <div className={style.PracticionerList}>
        <div className={style.flexSide}>
          <h2 className={style.headingPracticioner}>
            Practitioners
          </h2>
          <p className={style.seeAll}>See All</p>
        </div>
        <ul className={style.PracticionerContainer}>
          <>
            <li
              className={style.PracticionerPerson}
              onClick={() => handleNavigate('123')}
            >
              <div className={style.imageContainer}>
                <img src={'image'} className={style.imgDoctor} />
              </div>
              <div className={style.PracticionerContentList}>
                <div className={style.PracticionerInnerContentList}>
                  <h5 className={style.profesiName}>{person.name}</h5>
                  {/* <p className={style.profesi}>{'jabatan'}</p> */}
                  <p className={style.profesiDesc}>{person.business}</p>
                  <div className={style.practicionerContact}>
                    <div className={style.flexRow}>
                      <Icons.Location color="#222" />
                      <p className={style.practionerLocation}>
                        {person.location}
                      </p>
                    </div>
                    <div className={style.flexRow}>
                      <Icons.Phone color="#222" />
                      <p className={style.practionerPhone}>{person.phone}</p>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </>
        </ul>
      </div>
    );
  },
);

//register all components in this file
registerPackageModule(module);
