import React from 'react';
import './BiometricAuth.scss';
import {NativeBiometric} from 'capacitor-native-biometric';
import style from './BiometricAuth.scss.json';
import {DefaultLayout} from '../layout/DefaultLayout';
import {Button} from 'lincd-mui-base/lib/components/Button';
import {useNavigate} from 'react-router-dom';
import {ROUTES} from '../routes';
import {useAuth} from 'lincd-auth/lib/hooks/useAuth';
import {Person} from 'lincd-schema/lib/shapes/Person';
import {ClientAccount} from '../shapes/ClientAccount';
import {EnableAuthenticatorButton} from 'lincd-authenticator/src/components/EnableAuthenticatorButton';

const BiometricAuth = () => {
  const navigate = useNavigate();
  const auth = useAuth<Person, ClientAccount>();
  const account = auth?.userAccount;
  const user = auth?.user;
  async function performBiometricVerificatin() {
    const result = await NativeBiometric.isAvailable();

    if (!result.isAvailable) {
      alert('Biometric Not Available');
      return;
    }

    // const Fingerprint = result.biometryType == BiometryType.FINGERPRINT;

    const verified = await NativeBiometric.verifyIdentity({
      reason: 'Biometric Scan Login',
      title: 'Emergent Lives',
      subtitle: 'Fingerprint to login.',
      maxAttempts: 3,
    })
      .then(() => {
        return true;
      })
      .catch((error) => {
        console.log(error);
        return false;
      });

    console.log('verified', verified);

    if (!verified) return;
  }
  return (
    <DefaultLayout isHome={false} colorHeader="black" showBackButton={true}>
      <div className={style.BiometricContainer}>
        <div className={style.BiometricInfo}>
          <div className={style.BiometricInner}>
            <h1>Please set up two-way authentication</h1>
            <div className={style.BiometricUser}>
              <img src="/images/male_user.png" />
            </div>
          </div>
          <p>
            To access all provider features you are required to set up two way
            authentication.
          </p>
          <div className={style.BiometricImageContainer}>
            <EnableAuthenticatorButton
              onClick={() => navigate(ROUTES.setupGoogleAuthenticator.path)}
            />
            {/* <Button
              className={style.googleAuth}
              endIcon={
                account?.twoFactorEnabled ? (
                  <img src="/images/empty_check.png" />
                ) : (
                  <img src="/images/empty_check.png" />
                )
              }
              onClick={() => navigate(ROUTES.setupGoogleAuthenticator.path)}
            >
              Google Authenticator
            </Button> */}
            <Button
              className={style.biometricAuth}
              endIcon={<img src="/images/empty_check.png" />}
            >
              Biometric Authentication
            </Button>
          </div>
        </div>
        <div className={style.ButtonContainer}>
          <Button
            className={style.buttonPrimary}
            onClick={performBiometricVerificatin}
          >
            Continue
          </Button>
        </div>
      </div>
      {/* Modal Configuration Complete */}
      {/* <div className={style.modalContainer}>
          <div className={style.modalOverlay}></div>
          <div className={style.modalContent}>
            <img src={'/images/shieldIcon.png'} />
            <h4>Configuration Completed</h4>
            <p>
              Hi, {user?.givenName}You have successfully set up two-way
              authentication. You can now access all provider features.
            </p>
            <Button
              className={style.buttonPrimary}
              onClick={() => navigate('/')}
            >
              Close
            </Button>
          </div>
        </div> */}
    </DefaultLayout>
  );
};

export default BiometricAuth;
