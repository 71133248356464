import React, {useCallback, useEffect, useState} from 'react';
import './QRCode.scss';
import style from './QRCode.scss.json';
// import ToggleSwitch from '../components/Switch';
import './Switch.scss';
import IconButton from '../components/IconButton';
import {ShowQR} from './ShowID';
import {useNavigate} from 'react-router-dom';
import {BarcodeScanner} from '@capacitor-community/barcode-scanner';
import {ROUTES} from '../routes';
import {useAuth} from 'lincd-auth/lib/hooks/useAuth';
import {packageName} from '../package';
import {Server} from 'lincd-server-utils/lib/utils/Server';

const QRCodeScanner = ({toggleCamera, scanResult}) => {
  const [menu, setMenu] = useState('');
  const navigate = useNavigate();
  const auth = useAuth();

  // const [reader, setReader] = useState<boolean>(true);
  const handleCameraButtonClick = () => {
    if (toggleCamera) {
      toggleCamera();
      setMenu('');
    }
  };

  const stopScan = () => {
    BarcodeScanner.showBackground();
    BarcodeScanner.stopScan();
  };

  // Define handleMenuToggle using useCallback
  const handleMenuToggle = useCallback((selectedMenu) => {
    setMenu(selectedMenu);
    stopScan();
  }, []);

  // const toggleNFC = () => {
  //   setReader(!reader);
  //   // const read = async () => {
  //   //   return new Promise((resolve) => {
  //   //     Nfc.addListener('nfcTagScanned', async (event) => {
  //   //       await Nfc.stopScanSession();
  //   //       console.log('Ini Log NFC', event);
  //   //       resolve(event.nfcTag);
  //   //       setReader(false);
  //   //     });

  //   //     Nfc.startScanSession();
  //   //   });
  //   // };
  //   // read();
  // };

  useEffect(() => {
    // Exit the useEffect if countdown reaches 0
    if (scanResult) {
      Server.call(packageName, 'onSuccessfulQRScan', scanResult);

      //then navigate to profile details
      // navigate(ROUTES.profileDetails.path + '?uid=' + scanResult);
      navigate(ROUTES.events.path + '?uid=' + scanResult, {
        state: true,
      });
    }
    // if (menu === 'nfc') toggleNFC();

    return () => {
      stopScan();
    };
    // Cleanup the timer on unmount
  }, [scanResult, navigate]);

  const ScannerGif = ({menus}) => {
    return (
      <div className={style.containerScanner}>
        {menus === '' ? <img src="/images/scan.webp" alt="Scanner" /> : null}
      </div>
    );
  };

  const renderMenuContent = () => {
    if (menu === 'qrcode') return <ShowQR />;
    // if (menu === 'nfc') return <ShowNFC reader={reader} />;
    return <div className={style.fullDisplay}></div>;
  };

  return (
    <div className={style.ContainerQR}>
      {renderMenuContent()}
      {!scanResult && <ScannerGif menus={menu} />}
      <div className={style.cardSheet}>
        <div className={`${style.flexSide} ${style.flexTitle}`}>
          <h3 className={style.cardTitle}>My Profile</h3>
          <img
            src="/images/Camera.png"
            className={style.imgIconCamera}
            onClick={handleCameraButtonClick}
            alt="Camera Icon"
          />
        </div>
        <div className={style.flexSide}>
          <IconButton
            iconImg="/images/qrcode.png"
            title="QR Code"
            active={menu === 'qrcode'}
            onClick={() => handleMenuToggle('qrcode')}
          />
          {/* <IconButton
            iconImg="/images/nfclogo.png"
            title="NFC"
            active={menu === 'nfc'}
            onClick={() => handleMenuToggle('nfc')}
          /> */}
        </div>
        <hr className={style.separator} />
        {/* <div className={style.flexSide}>
          <p className={`${style.cardParagraph} ${style.halfWidth}`}>
            Automatic NFC scanning even in the background
          </p>
          <ToggleSwitch
            defaultChecked={true}
            containerClassName="switch-container"
            inputClassName="switch-input"
            onToggle={toggleNFC}
            buttonClassName="switch-button"
          />
        </div> */}
      </div>
    </div>
  );
};

export default QRCodeScanner;
