import React, {useEffect, useState} from 'react';
import './Welcome.scss';
import {default as style} from './Welcome.scss.json';
import {DefaultLayout} from '../layout/DefaultLayout';
import {Button} from '../components/Button';
import {useNavigate} from 'react-router-dom';
import {ROUTES} from '../routes';
import {useAuth} from 'lincd-auth/lib/hooks/useAuth';
import {TermsAndConditions} from '../components/TermsAndConditions';
import {Person} from 'profile-plus/lib/shapes/Person';
import {Spinner} from '../components/Spinner';

export default function Welcome() {
  const navigate = useNavigate();
  const auth = useAuth<Person>();
  const user = auth.user;
  const account = auth.userAccount;

  // loading spinner
  const [loggingIn, setLoggingIn] = useState<boolean>(false);

  // check if user is already logged in
  useEffect(() => {
    if(user){
      console.log("### USER: ", user);
      if (user.profileSetupCompleted) {
        navigate(ROUTES.events.path);
      } else {
        navigate(ROUTES.allAboutYou.path);
      }
    }
  }, [auth.user]);

  const goToSignin = () => {
    navigate(ROUTES.signin.path);
  };

  return (
    <>
      {loggingIn ? (
        <Spinner />
      ) : (
        <DefaultLayout
          isHome={false}
          colorHeader="black"
          showHeader={false}
          className={style.layout}
          showFooter={false}
          resetTopSafeArea={true}
        >
          <div className={style.Root}>
            <div>
              <div>
                <img src="images/welcome-crop@3x.png" />
              </div>
              <div className={style.logo}>
                <p>welcome to</p>
                <img src="images/EL-logo.svg" alt="emergent lives logo" />
              </div>
            </div>
            <div className={style.wrapper}>
              <div className={style.started}>
                <p>Ready to embark new journey with us?</p>
                <Button
                  color="primary"
                  className={style.button}
                  onClick={goToSignin}
                >
                  Get Started
                </Button>
                <TermsAndConditions />
              </div>
            </div>
          </div>
        </DefaultLayout>
      )}
    </>
  );
}
