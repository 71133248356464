import React,{forwardRef} from 'react';
import './Divider.scss';
import style from './Divider.scss.json';

interface DividerProps
{
  text?: string;
}

export const Divider = forwardRef<HTMLDivElement,DividerProps>(
  ({text},ref) => {
    return (
      <div ref={ref} className={style.DividerRoot}>
        <hr />
        {text && <p>{text}</p>}
      </div>
    );
  },
);

Divider.displayName = 'Divider';
