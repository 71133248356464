import {Link,useLocation} from 'react-router-dom';
import React,{HTMLAttributes,useState} from 'react';
import './Header.scss';
import style from './Header.scss.json';
import {Icons} from '../components/Icons';
import {ROUTES} from '../routes';
import {useStyles} from 'lincd/lib/utils/Hooks';
import {cl} from 'lincd/lib/utils/ClassNames';

const NavMenu = () => {
  return (
    <nav className={style.navigation}>
      {Object.keys(ROUTES).map((routeName,i) => {
        const route = ROUTES[routeName];
        return (
          <Link key={i} to={route.path}>
            {route.name}
          </Link>
        );
      })}
    </nav>
  );
};

interface HeaderProps extends HTMLAttributes<HTMLDivElement>
{
  onBack?: () => void;
  title?: string;
  className?: string;
  showBackButton?: boolean;
  color?: string;
  isHome?: boolean;
}

export function Header({
                         onBack,
                         title,
                         // className,
                         color,
                         isHome,
                         showBackButton = false,
                         ...props
                       }: HeaderProps)
{
  const [showMenu,setShowMenu] = useState(false);
  const location = useLocation();

  // extend header style
  props = useStyles(props,style.header);

  let headerClassName = ' ';
  switch (location.pathname)
  {
    case '/connections':
    case '/scanner':
    case '/profile':
    case '/profile/:uid':
      headerClassName += `${style.connectionsHeader}`;
      break;
    default:
      break;
  }

  const hideHeaderandMenu = () => {
    if (location.pathname === '/signin' || location.pathname === '/')
    {
      return null; // Don't show the back button and burger menu on '/signin'
    }

    return (
      <div className={style.backNavigator}>
        {isHome ? (
          <Icons.MenuBar color={color} onClick={() => setShowMenu(!showMenu)} />
        ) : (
          showBackButton ? <Icons.ArrowBack color={color} onClick={onBack} /> : null
        )}
        {showMenu && <NavMenu />}
      </div>
    );
  };

  return (
    <header className={cl(props.className) + `${headerClassName}`}>
      <div className={style.headerWrapper}>
        {hideHeaderandMenu()}
        {title && (
          <p
            className={`${
              color === 'white' ? style.whiteNavText : style.blackNavText || ''
            }`}
          >
            {title}
          </p>
        )}
        <div></div>
        {/* icons menu bar is temporary, currently use this for easy navigation */}
      </div>
    </header>
  );
}
