import React from 'react';
import './EventCard.scss';
import style from './EventCard.scss.json';
import {linkedComponent, registerPackageModule} from '../package';
import {Icons} from './Icons';
import {Event} from 'lincd-schema/lib/shapes/Event';
import {convertToLocalDate, getFormatTime} from '../helper/convertTime';
import {useNavigate} from 'react-router-dom';
import {ROUTES} from '../routes';

export const EventCard = linkedComponent<Event>(
  Event.request((event) => [
    event.name,
    event.identifier,
    event.location,
    event.image?.contentUrl,
    event.startDate as any,
    event.endDate as any,
    event.alternateName,
  ]),
  ({linkedData}) => {
    const navigate = useNavigate();
    const [name, id, location, image, start, end, summary] = linkedData;
    const handleEvent = () => {
      navigate(ROUTES.index.path.replace(':id', id));
    };
    return (
      <div className={style.EventList} key={id} onClick={handleEvent}>
        <div className={style.event}>
          <div className={style.event__pictureContainer}>
            {/* <p className={style.event__place}>{location}</p> */}
            <img className={style.event__picture} src={image} alt="card" />
            {/* <div className={style.event__picture} style={{backgroundImage: `url(${image})`}} /> */}
          </div>
          <div className={style.event__details}>
            <div className={style.event__title}>
              <h4>{name}</h4>
              <p>{summary}</p>
              <div className={style.event__descContainer}>
                <div className={style.event__desc}>
                  <Icons.Calendar color="#757575" />
                  <p>{convertToLocalDate(start)}</p>
                </div>
                <div className={style.event__desc}>
                  <Icons.Clock color="#757575" />
                  <p>{getFormatTime(end)}</p>
                </div>
              </div>
            </div>
            {/*<div className={style.event__icon}>*/}
            {/*  <EventbriteWidget eventId={id} />*/}
            {/*</div>*/}
          </div>
        </div>
      </div>
    );
  },
);
//register all components in this file
registerPackageModule(module);
