import React,{useEffect,useState} from 'react';
import style from './Footer.scss.json';
import './Footer.scss';
import {useLocation,useNavigate} from 'react-router-dom';
import {cl} from 'lincd/lib/utils/ClassNames';
import {useCapacitor} from 'lincd-capacitor/lib/hooks/useCapacitor';

// import {reactLocalStorage} from 'reactjs-localstorage';
interface FooterItem
{
  ImageSource: string;
  ListName: string;
  ImageSourceActive: string;
}

interface FooterProps
{
  sourceData?: FooterItem[];
}

// Source Data for Navigation
export const FOOTER_MENU = [
  {
    ListName: 'Events',
    ImageSource: '/images/footer_img/Events.png',
    ImageSourceActive: '/images/footer_img/Events_On.png',
  },
  {
    ListName: 'Connections',
    ImageSource: '/images/footer_img/Contact.png',
    ImageSourceActive: '/images/footer_img/Contact_On.png',
  },
  {
    ListName: 'Scan',
    ImageSource: '/images/footer_img/handshake.gif',
    ImageSourceActive: '/images/footer_img/scanCentreOff.png',
  },
  {
    ListName: 'Quests',
    ImageSource: '/images/footer_img/Adventure.png',
    ImageSourceActive: '/images/footer_img/Adventure_On.png',
  },
  {
    ListName: 'Profile',
    ImageSource: '/images/footer_img/Person.png',
    ImageSourceActive: '/images/footer_img/Person_On.png',
  },
];


const Footer: React.FC<FooterProps> = () => {
  //this can come from props when made into reusable component
  const sourceData = [...FOOTER_MENU];
  // let capacitor = useCapacitor();
  // if(capacitor.Capacitor.getPlatform() === 'web') {
  //   //remove the Scan item from the footer menu
  //   sourceData.splice(2, 1);
  // }


  const [activeItem,setActiveItem] = useState<FooterItem | null>(null);
  const navigate = useNavigate();
  const location = useLocation();
  // const currentLink = reactLocalStorage.get('currentLink');

  const handleItemClick = (item: FooterItem) => {
    setActiveItem(item === activeItem ? null : item);
    switch (item.ListName)
    {
      case 'Events':
        navigate('/events');
        break;
      case 'Connections':
        navigate('/connections');
        break;
      case 'Quests':
        navigate('/quests');
        break;
      case 'Profile':
        navigate('/profile');
        break;
      case 'Scan':
        navigate('/scanner');
        break;
    }
  };

  useEffect(() => {
    // Find the item corresponding to the current location
    const initialItem = sourceData?.find((item) => {
      switch (location.pathname)
      {
        case '/events':
          return item.ListName === 'Events';
        case '/connections':
          return item.ListName === 'Connections';
        case '/quests':
          return item.ListName === 'Quests';
        case '/profile':
          return item.ListName === 'Profile';
        default:
          return false;
      }
    });

    if (initialItem)
    {
      setActiveItem(initialItem);
    }
  },[location.pathname,sourceData]);

  return (
    <div className={style.Container}>
      <ul className={style.listFooter}>
        {sourceData &&
          sourceData.map((item) => (
            <li
              className={style.footer}
              key={item.ListName}
              onClick={() => handleItemClick(item)}
            >
              <div className={cl(item.ListName === 'Scan' && style.scanLoader)}>
                <img
                  src={
                    activeItem === item
                      ? item.ImageSourceActive
                      : item.ImageSource
                  }
                  className={
                    cl(item.ListName === 'Scan' ? style.imgScan : style.imgSrc,
                      activeItem === item && style.active)

                  }
                  alt={item.ListName}
                />
              </div>
              <p className={style.title}>{item.ListName}</p>
            </li>
          ))}
      </ul>
    </div>
  );
};

export default Footer;
