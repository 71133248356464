import React, {useEffect, useState} from 'react';
import {DefaultLayout} from '../layout/DefaultLayout';
import './Home.scss';
import style from './Home.scss.json';
import {MainEvent} from '../components/mainEvent';
import {useLocation, useParams} from 'react-router-dom';
import {Icons} from '../components/Icons';
import SearchBar from '../components/SearchBar';
import {Server} from 'lincd-server-utils/lib/utils/Server';
import {packageName} from '../package';
import {Event} from 'lincd-schema/lib/shapes/Event';
import {ClientEvent} from '../shapes/ClientEvent';
import {Person} from 'profile-plus/lib/shapes/Person';
import {ShapeSet} from 'lincd/lib/collections/ShapeSet';
import {PracticionerCard} from '../components/PracticionerCard';
import {getShapeOrSubShape} from 'lincd/lib/utils/ShapeClass';
import Masonry from 'react-responsive-masonry';

export default function Home() {
  const [searchQuery, setSearchQuery] = useState('');
  const [cardHeights, setCardHeights] = useState({});
  const [practitioners, setPractitioners] = useState<ShapeSet<Person>>(
    new ShapeSet(),
  );
  const [sourceData, setSourceData] = useState<ShapeSet<Person>>(
    new ShapeSet(),
  );

  const {id} = useParams();
  const [event, setEvent] = useState<Event>();
  const location = useLocation();

  const handleInputChange = (query) => {
    if (query === '') {
      setPractitioners(sourceData); // Restore source data
    } else {
      query = query.toLowerCase();
      setSearchQuery(query);
      const searchResult = practitioners.filter((item) =>
        (item.givenName + ' ' + item.familyName).toLowerCase().includes(query),
      );
      setPractitioners(searchResult);
    }
  };

  useEffect(() => {
    const localMemEvent = ClientEvent.getLocalInstances().find((ev) => {
      return ev.identifier === id;
    });
    // if (localMemEvent) {
    //   setEvent(localMemEvent);
    // } else {
    Server.call(packageName, 'loadEventAndProviders', id).then(
      ({clientEvent, providerEvent, providers}) => {
        setEvent(clientEvent || providerEvent);

        //tmp fix
        providers = providers.map((provider) => {
          return getShapeOrSubShape(provider.node, Person);
        });
        setPractitioners(providers);
        setSourceData(providers);
      },
    );
    // .}
  }, [id]);
  const handleResize = (index, contentRect) => {
    setCardHeights((prevHeights) => ({
      ...prevHeights,
      [index]: contentRect.bounds.height,
    }));
  };

  const sortPracticioners = (practitioners) => {
    return practitioners.sort((a, b) => {
      //make sure practitioners that do not have a profile picture are at the bottom of the list

      if (!a.profilePicture && b.profilePicture) return 1;
      if (a?.profilePicture && !b?.profilePicture) return -1;
      // let orgA: Organization =
      //   Organization.getAffiliationsOf(a).first();
      // let orgB: Organization =
      //   Organization.getAffiliationsOf(b).first();
      //
      // if (!orgA?.image && orgB?.image) return 1;
      // if (orgA?.image && !orgB?.image) return -1;
      return 0;
    });
  };

  return (
    <DefaultLayout
      isHome={true}
      showHeader={false}
      colorHeader={'white'}
      currentLink={location.pathname}
    >
      <div className={style.container}>
        <div className={style.HeaderInformation}>
          <Icons.Avatar
            image="/images/el_icon.png"
            className={style.avatarImage}
          />
          <h5 className={style.headerTitle}>Emergent Lives</h5>
        </div>
        <SearchBar
          placeholder="Search Practicioners, Health Coach..."
          onSearch={() => ''}
          onInputChange={handleInputChange}
          containerClassName={style.homeSearch}
        />
        <MainEvent of={event} key={'event'} />
        <div className={style.flexSide}>
          <h2 className={style.headingPracticioner}>Practitioners</h2>
        </div>
        <div className={style.PracticionerList}>
          {practitioners && (
            <Masonry
              columnsCount={2}
              gutter="20px"
              className={style.PracticionerContainer}
            >
              {sortPracticioners(practitioners).map((practicioner) => {
                return (
                  <>
                    <PracticionerCard
                      key={practicioner.toString()}
                      of={practicioner}
                    />
                  </>
                );
              })}
            </Masonry>
          )}
        </div>
      </div>

      {/* <FloatingButton imageSrc="/images/tombol.png" onClick={() => ''} /> */}
    </DefaultLayout>
  );
}
