import {NamedNode} from 'lincd/lib/models';
import {linkedShape} from '../package';
import {emergentLives} from '../ontologies/emergent-lives';
import {UserAccount} from 'lincd-sioc/lib/shapes/UserAccount';
import {objectProperty} from 'lincd/lib/utils/ShapeDecorators';
import {Server} from 'lincd-server-utils/lib/utils/Server';
import {ImageObject} from 'lincd-schema/lib/shapes/ImageObject';

@linkedShape
export class ClientAccount extends UserAccount
{
  static targetClass: NamedNode = emergentLives.ClientAccount;

  @objectProperty({
    path: emergentLives.qrCode,
    shape: ImageObject,
    maxCount: 1,
  })
  get qrCode(): ImageObject
  {
    return this.getOneAs(emergentLives.qrCode,ImageObject);
  }

  set qrCode(val: ImageObject)
  {
    this.overwrite(emergentLives.qrCode,val.node);
  }

  getPersonalQRCode()
  {
    return Server.call(this,'getPersonalQRCode');
  }

  getConnections()
  {
    return Server.call(this,'getConnections');
  }

  getConnectionTo(person)
  {
    return Server.call(this,'getConnectionTo',person);
  }
}
